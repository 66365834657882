import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Alert from "../components/Alert"
import { SubmitButton } from "../components/Buttons"
import { GenericInputField } from "../components/FormElements"
import { JoinCalendarRequest, useCalendarFetchAPI, useCalendarMutationAPI } from "../hooks/api/useCalendarsAPI"
import bg from "../assets/bg.jpg"

const CalendarJoin = () => {
  const { calendarId } = useParams<{ calendarId: string }>()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<JoinCalendarRequest>({ mode: "onSubmit" })

  const { data } = useCalendarFetchAPI(calendarId)
  const calendarMutations = useCalendarMutationAPI(calendarId)

  const navigate = useNavigate()

  const onSubmit = handleSubmit(async (formData) => {
    calendarMutations.join.mutate(formData)
  })
  const { error, isSuccess, isLoading } = calendarMutations.join

  useEffect(() => {
    if (isSuccess) {
      toast.success("Joined successfully")
      navigate("/")
    }
  })

  return (
    <>
      <section
        style={{ backgroundImage: `url(${bg})` }}
        className={` bg-no-repeat bg-cover bg-center min-h-screen bg-gray-700 bg-blend-multiply bg-opacity-60 pt-20`}
      >
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
            <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                Join the Calender: {data?.data.name}
              </h1>
              <form className="space-y-4 md:space-y-6">
                <div>
                  <GenericInputField
                    id="password"
                    inputType="text"
                    registerHandler={() =>
                      register("password", {
                        required: "Please enter the calendar password to join",
                      })
                    }
                    placeholder="Enter calendar password"
                    errors={errors.password}
                  >
                    Password
                  </GenericInputField>
                </div>

                <SubmitButton onClick={onSubmit} isLoading={isLoading} fullWidth>
                  Join
                </SubmitButton>
                {error && error.response?.status === 401 && (
                  <Alert type="error">Wrong or old password, please contact the owner of the calendar.</Alert>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CalendarJoin
