import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import useAxios from "../../utils/useAxios";

interface AssetUpdateRequest {
  file: File | null
  type: string
  size: number
  calendarId: string
}

interface AssetUpdateResponse {
  id: string
}
export interface AssetUploadForm {
  file: FileList
}

const useAssetMutationAPI = () => {
    const apiClient = useAxios();

  
  
    const createAsset = useMutation<
      AxiosResponse<AssetUpdateResponse>,
      AxiosError,
      AssetUpdateRequest,
      unknown
    >((data) => apiClient.post(`calendars/assets/`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }))

    return {
        create: createAsset
    }
}

export {useAssetMutationAPI}