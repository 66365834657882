type Stage = "dev" | "staging" | "production"

let stage = import.meta.env.VITE_STAGE as Stage
if (stage === undefined) {
  console.warn("VITE_STAGE env variable is undefined. Using dev as fallback")
  stage = "dev"
}

const defaults = {
  localStorageKeyAccessToken: "__at__",
  localStorageKeyRefreshToken: "__rt__",
  googleOAuthClientId: "991633809897-0hos5f6bge2fil0g5lqjlh5bd29rsg4a.apps.googleusercontent.com",
  microsoftOAuthClientId: "cd287f55-2b2b-42f4-b39c-b0b1454f0c4b",
  apiBaseUrl: "http://localhost:8000",
  appBaseUrl: "http://localhost:5173",
}

const dev = {
  ...defaults,
}

const staging = {
  ...defaults,
  apiBaseUrl: "https://api.staging.julr.gemba-digital.de",
  appBaseUrl: "https://staging.julr.gemba-digital.de",
}

const production = { ...defaults, apiBaseUrl: "https://api.julr.net", appBaseUrl: "https://julr.net" }

function getConfig() {
  switch (stage) {
    case "staging":
      return staging
    case "production":
      return production
    default:
      return dev
  }
}
const config = getConfig()

export default {
  ...config,
  stage,
}
