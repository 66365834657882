import { useForm } from "react-hook-form"
import { SubmitButton } from "../components/Buttons"
import { GenericInputField } from "../components/FormElements"
import { useCalendarMutationAPI } from "../hooks/api/useCalendarsAPI"
import { CreateCalendarRequest } from "../types/calendar"

const CalendarNew = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCalendarRequest>({ mode: "onSubmit" })

  const calendarMutations = useCalendarMutationAPI()

  const onSubmit = handleSubmit(async (formData) => {
    calendarMutations.create.mutate(formData)
  })

  const { isLoading } = calendarMutations.create

  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white pt-20 px-6 pb-6 min-h-screen">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white mb-6">
        Create Your Calendar
      </h1>
      <form className="space-y-4 md:space-y-6">
        <div>
          <GenericInputField
            id="name"
            inputType="text"
            registerHandler={() =>
              register("name", {
                required: "Please enter a name for your calendar",
                maxLength: {
                  value: 40,
                  message: "Name must be less than 40 characters",
                },
              })
            }
            placeholder="The name/title of your calendar"
            errors={errors.name}
          >
            Name
          </GenericInputField>
        </div>
        <div>
          <GenericInputField
            id="description"
            inputType="textArea"
            registerHandler={() =>
              register("description", {
                required: "Please enter a description for your calendar, you can change it later again",
              })
            }
            errors={errors.description}
            placeholder={"Your calendar description..."}
            rows={6}
          >
            Description
          </GenericInputField>
        </div>

        <SubmitButton onClick={onSubmit} isLoading={isLoading} fullWidth>
          Create Calendar{" "}
        </SubmitButton>
      </form>
    </div>
  )
}

export default CalendarNew
