import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import * as auth from "../AuthProvider"
import { User } from "../types/auth"
import FullPageSpinner from "../components/FullPageSpinner"
import FullPageErrorFallback from "../components/FullPageErrorFallback"
import { useAsync } from "../hooks/useAsync"
import config from "../utils/config"
import axios from "axios"

interface AuthContextType {
  user: User | null
  login: (form: auth.LoginForm) => Promise<unknown>
  googleLogin: (accessToken: string) => Promise<unknown>
  microsoftLogin: (accessToken: string) => Promise<unknown>
  register: (form: auth.RegisterForm) => Promise<unknown>
  logout: () => void
  authTokens: auth.AuthTokens | null
  setAuthTokens: (authTokens: auth.AuthTokens | null) => void
}

export const AuthContext = createContext<AuthContextType>(null!)

const AuthProvider = (props: { children: ReactNode }) => {
  const [authTokens, setAuthTokens] = useState<auth.AuthTokens | null>(() => auth.getTokens())
  const {
    state: { data: user },
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync<User>()

  const bootstrapAppData = async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let user: User = null!
    let localStorageAuthTokens = auth.getTokens()
    if (localStorageAuthTokens && localStorageAuthTokens.access.length > 2) {
      if (auth.isTokenExpired(localStorageAuthTokens.access)) {
        if (auth.isTokenExpired(localStorageAuthTokens.refresh)) {
          auth.logout()
        }
        const newAuthTokens = await auth.refreshTokens(localStorageAuthTokens.refresh)
        if (newAuthTokens) {
          setAuthTokens(newAuthTokens)
          localStorageAuthTokens = newAuthTokens
        } else {
          auth.logout()
        }
      }

      const data = await axios.get("/users/me", {
        baseURL: config.apiBaseUrl,
        headers: {
          Authorization: `Bearer ${localStorageAuthTokens?.access}`,
        },
      })

      user = data.data
    }

    return user
  }

  const login = useCallback(
    (form: auth.LoginForm) =>
      auth.login(form).then((res) => {
        // get tokens / login
        setAuthTokens(res)
        // set the user in context
        bootstrapAppData().then((user) => {
          setData(user)
        })
      }),
    [setData]
  )

  const googleLogin = useCallback(
    (accessToken: string) =>
      auth.googleLogin(accessToken).then((res) => {
        // get tokens / login
        setAuthTokens(res)
        // set the user in context
        bootstrapAppData().then((user) => {
          setData(user)
        })
      }),
    [setData]
  )
  const microsoftLogin = useCallback(
    (accessToken: string) =>
      auth.microsoftLogin(accessToken).then((res) => {
        // get tokens / login
        setAuthTokens(res)
        // set the user in context
        bootstrapAppData().then((user) => {
          setData(user)
        })
      }),
    [setData]
  )
  const register = useCallback(
    (form: auth.RegisterForm) =>
      auth.register(form).then((res) => {
        // get tokens / login
        setAuthTokens(res)
        // set the user in context
        bootstrapAppData().then((user) => {
          setData(user)
        })
      }),
    [setData]
  )

  const logout = useCallback(() => {
    auth.logout()
    setData(null!)
  }, [setData])

  // Get user data when initialize / reload the page
  useEffect(() => {
    const appDataPromise = bootstrapAppData()
    run(appDataPromise)
  }, [run])

  const value = useMemo(
    () => ({
      user,
      login,
      googleLogin,
      microsoftLogin,
      logout,
      register,
      authTokens,
      setAuthTokens,
    }),
    [register, login, googleLogin, microsoftLogin, logout, user, authTokens]
  )
  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined || context === null) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
