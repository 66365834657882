import { Calendar, Door } from "../types/calendar"
import bg from "../assets/bg.jpg"
import door1 from "./1.png"
import door2 from "./2.png"
import door3 from "./3.jpg"
import door4 from "./4.png"
import door5 from "./5.png"
import door6 from "./6.png"
import door7 from "./7.png"
import door8 from "./8.png"
import door9 from "./9.jpg"
import door10 from "./10.jpg"
import door11 from "./11.png"
import door12 from "./12.jpg"
import door13 from "./13.jpg"
import door14 from "./14.png"
import door15 from "./15.jpg"
import door16 from "./16.jpg"
import door17 from "./17.jpg"
import door18 from "./18.jpg"
import door19 from "./19.jpg"
import door20 from "./20.jpg"
import door21 from "./21.jpg"
import door22 from "./22.jpg"
import door23 from "./23.jpg"
import door24 from "./24.jpg"

const nullId = "00000000-0000-0000-0000-000000000000"

export const demoDoors: Door[] = [
  {
    id: "1",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door1 },
    ownerName: "Kannika",
    calendarId: "demo",
    day: 1,
    artist: "The Beautiful Girls",
    title: "And We'll Dance On The Ashes Of Whats Left",
    mediaUrl: "https://soundcloud.com/thebeautifulgirls/and-well-dance-on-the-ashes-of",
    body: "The Beautiful Girls is just my most favourite band ever! They were the soundtrack for me and my boyfriend on our road trip through the US in 2012. We were listening to the “We're Already Gone” album back and forth while driving forever through the beautiful nature of North America.",
    isReady: true,
    rank: 1,
  },
  {
    id: "2",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door2 },
    ownerName: "Juan",
    calendarId: "demo",
    day: 2,
    artist: "Kaya Project",
    title: "One Hundred Lights (Grouch Remix)",
    mediaUrl: "https://soundcloud.com/heydaymediagroup-1/kaya-project-one-hundred-lights-grouch-remix-1",
    body: "I have loved this song for quite a while now. I will never forget hearing it for the first time. I was listening to a friend's playlist on Spotify while hiking through the awesome nature in the mountains of Vietnam near Da Lat. So this song was painting a beautifully acoustic background for my hike. Hope you can enjoy it as much as I do. Cheers, Juan",
    isReady: true,
    rank: 2,
  },
  {
    id: "3",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door3 },
    ownerName: "Fiona",
    calendarId: "demo",
    day: 3,
    artist: "Dance Soldiah, Chezidek",
    title: "Vegan Style",
    mediaUrl: "https://www.youtube.com/watch?v=FwIn6hVb_z4",
    body: "At the end of high school I was really into reggae music (still am a bit 😀). One of my favourite reggae artists is Chezidek. He did a collaboration with Dance Soldiah last year which brought me straight back to my reggae days. The song is called 'Vegan Style' and the hook goes straight to your ear and will follow you for quite a bit of time.",
    isReady: true,
    rank: 3,
  },
  {
    id: "4",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door4 },
    ownerName: "Ben",
    calendarId: "demo",
    day: 4,
    artist: "Tool",
    title: "Vicarious",
    mediaUrl: "https://www.youtube.com/watch?v=N26N7lQHNW8",
    body: "They say there are only two groups of people in the world. The ones who love Tool and the ones who don't know Tool. I finally moved from group two to group one a couple of years ago. This year I managed to get some tickets for their concert in Cologne. At one point one of my friends turned to me and said with a lot of enthusiasm and a big smile on his face 'And that is only ONE drummer!'. I think that summed up pretty well how unique and awesome Danny Careys drumming style is 🙂 I mean, they have so many incredible amazing songs, it is hard to pick just one for this calendar. I went for Vicarious in the end because I just love the lyrics so much. Hope you'll like it too.",
    isReady: true,
    rank: 4,
  },
  {
    id: "5",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door5 },
    ownerName: "Freddy",
    calendarId: "demo",
    day: 5,
    artist: "Deep Purple",
    title: "Burn",
    mediaUrl: "https://www.youtube.com/watch?v=dUcBOw4C8xA",
    body: "Just love this song from deep purple. The drums and the whole song are just so energetic. Started to listen to it again a lot this summer since I put it in my gym playlist :)",
    isReady: true,
    rank: 5,
  },
  {
    id: "6",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door6 },
    ownerName: "Max",
    calendarId: "demo",
    day: 6,
    artist: "Opiuo",
    title: "Quack Fat",
    mediaUrl: "https://www.youtube.com/watch?v=Y1MkJOe3w7U",
    body: "Man, Opiuo is just insane. I have never heard anything like this before and I love it. I saw Opiuo live at a festival back in the days and I just didn't know what was happening to my ears. The bass, the trumpet sounds, the melodies. Just totally new. I picked Quack Fat for you guys since it also has a very nice music video which is also made with a lot of effort and love. Enjoy!",
    isReady: true,
    rank: 6,
  },
  {
    id: "7",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door7 },
    ownerName: "Nick",
    calendarId: "demo",
    day: 7,
    artist: "Tenacious D",
    title: "Kickapoo",
    mediaUrl: "https://www.youtube.com/watch?v=MHgzzkgQC0Y",
    body: "I watched the movie 'The Pick of Destiny' at least 42.359.239 times. Me and my friends from my school band were totally rocking out to the soundtrack which until today I think is just epic. A couple of months ago I watched the movie again with my friends and since then I can't stop listening to the soundtrack again. Damn you, Jack Black!",
    isReady: true,
    rank: 7,
  },
  {
    id: "8",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door8 },
    ownerName: "Maya",
    calendarId: "demo",
    day: 8,
    artist: "Bea Miller",
    title: "Playground",
    mediaUrl: "https://soundcloud.com/beamiller/playground-from-the-series",
    body: "After watching Arcane on Netflix this year, I put “Playground” at the beginning of one of my playlists. Every morning in the summer when I was leaving my apartment I started my commute to work with this song on my headphones. It became the soundtrack for my neighbourhood when I was walking to the train station. Great song from a great series.",
    isReady: true,
    rank: 8,
  },
  {
    id: "9",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door9 },
    ownerName: "Jasper",
    calendarId: "demo",
    day: 9,
    artist: "Red Sparowes",
    title: "A Swarm",
    mediaUrl: "https://www.youtube.com/watch?v=jA0u1_m8jsE",
    body: "No matter what day- or nighttime it is or in what mood I'm in, this song picks me up where I am and takes me where I want to go, even if I did not know where to go until then.",
    isReady: true,
    rank: 9,
  },
  {
    id: "10",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door10 },
    ownerName: "Milli",
    calendarId: "demo",
    day: 10,
    artist: "Stimming",
    title: "November Morning",
    mediaUrl: "https://www.youtube.com/watch?v=HSBp5YzF_tQ",
    body: "A friend of mine showed me this one a long time ago in my small student apartment and I fell in love with it from the first beat. Every bit of sound and noise is just perfectly placed in balance. This one is timeless!",
    isReady: true,
    rank: 10,
  },
  {
    id: "11",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door11 },
    ownerName: "Sieglinde",
    calendarId: "demo",
    day: 11,
    artist: "Edgar Broughton Band",
    title: "Evening over Rooftops",
    mediaUrl: "https://www.youtube.com/watch?v=mJYkzYLfHIE",
    body: "In the mid-70s I heard this song at a friend's house and the lyrics fit my melancholic mood perfectly. I thought long and hard about whether I should buy the LP, because it was the ONLY great song on this LP, which also had the most ghastly cover I had ever seen. (Hog halves hanging in a slaughterhouse and a naked human in between).Today I still like to listen to the song at full volume when I'm driving in the car - one of many songs on my oldie playlist 😉",
    isReady: true,
    rank: 11,
  },
  {
    id: "12",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door12 },
    ownerName: "Annie",
    calendarId: "demo",
    day: 12,
    artist: "Haloo Helsinki!",
    title: "Beibi",
    mediaUrl: "https://www.youtube.com/watch?v=CyMpHXAjosA",
    body: "An absolute catchy tune from 2014! During my semester abroad in Finland, this song followed me everywhere - whether it was playing on the radio, in the mall or at legendary university parties. Even though it never made it onto my list of favourite songs as a classic chart hit, it brings back a lot of memories of cold and dark but also incredibly exciting months in the Finnish winter.",
    isReady: true,
    rank: 12,
  },
  {
    id: "13",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door13 },
    ownerName: "Paolo",
    calendarId: "demo",
    day: 13,
    artist: "Fréro Delavega",
    title: "Le Chant Des Sirènes",
    mediaUrl: "https://www.youtube.com/watch?v=Mqb8weZrKN4",
    body: "On the road with a donkey in France. On a small farm somewhere in the middle of France, we started with our 'borrowed' donkey on a multi-day hike through the small surrounding villages. This song was playing all day long by the owner of the donkey. When we visited his café we didn't have to wait long until we could hear it again. And also on the return trip by car, the song was often heard on the radio.",
    isReady: true,
    rank: 13,
  },
  {
    id: "14",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door14 },
    ownerName: "Wanda",
    calendarId: "demo",
    day: 14,
    artist: "Jimmy Scott",
    title: "Nothing compares 2U",
    mediaUrl: "https://www.youtube.com/watch?v=bvHt-n-nBlg",
    body: "I've always liked this song, and about 20 years ago I received this version from a friend in Spain. I wondered for a long time who would interpret it and was surprised when I heard the name and ....  that it was the voice of Jimmy Scott….an interesting and special interpretation.",
    isReady: true,
    rank: 14,
  },
  {
    id: "15",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door15 },
    ownerName: "Elena",
    calendarId: "demo",
    day: 15,
    artist: "Ferris MC",
    title: "Was ist geblieben",
    mediaUrl: "https://www.youtube.com/watch?v=eDBylf0sNqY",
    body: "On a long car ride I discovered the audio book 'I have everything out of control' and listened to it from start till end within a few days. Sascha Reimann (the author) is also a musician and processes, among other things, his musical development in the book. Of course I had to listen to it! As a punk fan I like his music and here comes one of my 'favourite songs' from him.",
    isReady: true,
    rank: 15,
  },
  {
    id: "16",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door16 },
    ownerName: "Iris",
    calendarId: "demo",
    day: 16,
    artist: "Goo Goo Dolls",
    title: "Iris",
    mediaUrl: "https://www.youtube.com/watch?v=NdYWuo9OFAw",
    body: "Nicolas Cage movies - a definite matter of taste. My parents agree, 'City of Angels' is a good movie. It even seems so good that the soundtrack may give a child a name. Whom did this good fortune befall? Me. I'll be honest, I really rarely hear the track. But when I hear my name, I hum a few lines to myself and think my parents could have a lot worse favourite movies. 😀",
    isReady: true,
    rank: 16,
  },
  {
    id: "17",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door17 },
    ownerName: "Pepe",
    calendarId: "demo",
    day: 17,
    artist: "John Lennon",
    title: "Imagine",
    mediaUrl: "https://youtu.be/VOgFZfRVaww",
    body: 'I was a typical shaky candidate when it came to transferring to the next grade. That means, the end of the school year was definitely my time to shine 😀 With a presentation about the communist ideas in "Imagine" I just about saved myself a passing grade in Politics and was able to transfer to 9th grade with everyone else. Every time I hear that song today, I break out in a sweat and get a little queasy. But everything works out in the end. Greetings go out to Mr. Klinger, thank you for not letting me down back then!',
    isReady: true,
    rank: 17,
  },
  {
    id: "18",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door18 },
    ownerName: "Kay",
    calendarId: "demo",
    day: 18,
    artist: "Ludwig van Beethoven",
    title: "Ode to Joy",
    mediaUrl: "https://www.youtube.com/watch?v=-kcOpyM9cBg",
    body: "On a trip with our club 10 years ago we met a group of musicians. They were on a small concert tour through Saxony. We got along well with them from the beginning. Unfortunately their tour was not as well attended as they would have liked. So we came up with a promotional idea during our cosy beer evening together. Only two days later we rocked a flash mob on Bautzen's marketplace. Beethoven in Bautzen and us right in the middle of it. The concert in the evening was more popular than ever and we were all full of joy.",
    isReady: true,
    rank: 18,
  },
  {
    id: "19",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door19 },
    ownerName: "Lilly",
    calendarId: "demo",
    day: 19,
    artist: "Chris Isaak",
    title: "Wicked Game",
    mediaUrl: "https://www.youtube.com/watch?v=jd-qI62gNJM",
    body: "I absolutely love this song! I rediscovered it this year during a choir concert in Berlin. The performance choir and the version they sang was simply amazing! Instantly I knew that this is gonna be the next song I am going to learn on the guitar. Next morning I looked up the guitar tabs and started playing right away. Such an evergreen!",
    isReady: true,
    rank: 19,
  },
  {
    id: "20",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door20 },
    ownerName: "Dave",
    calendarId: "demo",
    day: 20,
    artist: "Xavier Rudd",
    title: "Spirit Bird",
    mediaUrl: "https://www.youtube.com/watch?v=RoVj6zBAkAM",
    body: "Xavier Rudd is certainly one of my all time favourite artists. I love him. If I were into guys, I'd definitely marry him right away. I saw him live in concert already twice and both of the times the atmosphere was mind blowing. The entire hall was filled up with love and gratitude. Although his new album is very nice as well, Spirit Bird is still my favourite song up until now. I hope you enjoy the song as much as I do!",
    isReady: true,
    rank: 20,
  },
  {
    id: "21",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door21 },
    ownerName: "Karen",
    calendarId: "demo",
    day: 21,
    artist: "MGMT",
    title: "Kids",
    mediaUrl: "https://www.youtube.com/watch?v=fe4EK4HSPkI",
    body: "I don't know why this song just popped up in my head again… Anyway, whenever I listen to it, I am thrown back 15 years in time, back in the days when I was studying. No matter which party we went to, this song was always on the playlist. A song with “good mood guarantee” :-) ",
    isReady: true,
    rank: 21,
  },
  {
    id: "22",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door22 },
    ownerName: "Simona",
    calendarId: "demo",
    day: 22,
    artist: "Fat Freddy's Drop",
    title: "Wandering Eye",
    mediaUrl: "https://www.youtube.com/watch?v=JtyCtksvSOg",
    body: "Whenever I'm finding myself in a bad mood I simply play this song, it always lifts me up :-) Try it for yourself!",
    isReady: true,
    rank: 22,
  },
  {
    id: "23",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door23 },
    ownerName: "Sheela",
    calendarId: "demo",
    day: 23,
    artist: "RY X",
    title: "Howling",
    mediaUrl: "https://www.youtube.com/watch?v=nx-eYQqkI2g",
    body: "I first encountered this song as a techno remix at a crazy party probably around 10 years ago. I just recently stumbled upon the original version. For me this song is strongly related with goose bumps all over my body!",
    isReady: true,
    rank: 23,
  },
  {
    id: "24",
    ownerId: nullId,
    ownerImg: { id: nullId, url: door24 },
    ownerName: "Arnd",
    calendarId: "demo",
    day: 24,
    artist: "Red Hot Chili Peppers",
    title: "Can't stop",
    mediaUrl: "https://www.youtube.com/watch?v=8DyziWtkfBw",
    body: "Wild memories come up when I listen to this tune. Los Angeles, me and my best buddy, lots of beer, an insane concert, soaked in sweat. These guys are living legends! ",
    isReady: true,
    rank: 24,
  },
]

export const demoCalendar: Calendar = {
  id: "demo",
  ownerId: nullId,
  name: "Our Crew Calendar (AI-Friends Edition)",
  description:
    "This calendar might give you a rough idea of how a calendar could look like. Depending on how much you and your friends/coworkers want to share, the stories may vary. Feel free to define a motto for this year's calendar or just let everyone just share their favourite song. (All images generated at This-Person-Does-not-Exist.com)",
  background: {
    id: nullId,
    url: bg,
  },
  doneDoors: 24,
  viewers: [],
  doors: demoDoors.map((door) => {
    return {
      id: door.id,
      day: door.day,
      ownerId: door.ownerId,
      ownerImg: {
        id: nullId,
        url: door.ownerImg.url,
      },
      isReady: door.isReady,
      rank: door.rank,
    }
  }),
}
