import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { FormDivider, FormSectionHeader, GenericInputField } from "../components/FormElements"
import { useCalendarFetchAPI, useCalendarMutationAPI } from "../hooks/api/useCalendarsAPI"
import bg from "../assets/bg.jpg"
import { useAssetMutationAPI } from "../hooks/api/useAssetsAPI"
import { toast } from "react-toastify"
import classNames from "classnames"
import { RiArrowGoBackLine } from "react-icons/ri"
import { ButtonLink, SubmitButton } from "../components/Buttons"

interface CalendarUpdateFormData {
  name: string
  description: string
}

const CalendarEdit = () => {
  const { calendarId } = useParams<{ calendarId: string }>()

  const { data, isSuccess } = useCalendarFetchAPI(calendarId)
  const calendarMutations = useCalendarMutationAPI(calendarId)
  const assetMutations = useAssetMutationAPI()
  const [backgroundFile, setBackgroundFile] = useState<File | null>(null)
  const [backgroundUrl, setBackgroundUrl] = useState<string>(bg)

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CalendarUpdateFormData>({ mode: "onSubmit" })

  const onSubmit = handleSubmit(async (formData) => {
    let assetId
    if (backgroundFile != null) {
      const finalAssetFormData = {
        file: backgroundFile,
        type: "calendarImg",
        size: backgroundFile?.size ? backgroundFile?.size : 0,
        calendarId: calendarId ? calendarId : "",
      }
      assetId = await (await assetMutations.create.mutateAsync(finalAssetFormData)).data.id
    }
    const finalFormData = {
      ...formData,
      background: assetId ? assetId : data?.data.background.id,
    }

    calendarMutations.update.mutate(finalFormData)
  })
  const { isSuccess: deletionIsSuccess } = calendarMutations.delete
  const handleDelete = () => {
    window.confirm("Are you sure you want to delete this calendar? All doors and their content will be deleted.") &&
      calendarMutations.delete.mutate()
  }

  useEffect(() => {
    if (deletionIsSuccess) {
      navigate("/calendars")
      toast.success("Calendar deleted")
    }
  }, [deletionIsSuccess])

  useEffect(() => {
    if (backgroundFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setBackgroundUrl(reader.result as string)
      }
      reader.readAsDataURL(backgroundFile)
    }
  }, [backgroundFile])

  useEffect(() => {
    if (data?.data?.background?.url != "") {
      setBackgroundUrl(data?.data.background ? data?.data?.background?.url : bg)
    } else {
      setBackgroundUrl(bg)
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      setValue("name", data?.data.name)
      setValue("description", data?.data.description)
    }
  }, [isSuccess])

  const DoorsReady = () => {
    const getPercent = () => {
      const percent = data?.data.doneDoors ? (data?.data.doneDoors / 24) * 100 : 0
      if (percent > 100) {
        return 100
      }
      return percent
    }
    if (data?.data.doneDoors === undefined) return null
    return (
      <div className="mb-6">
        <div className="flex justify-between mb-1 ">
          <span
            className={classNames("text-base font-medium text-primary-700", {
              "text-green-700": data?.data.doneDoors >= 24,
            })}
          >
            Done Doors
          </span>
          <span
            className={classNames("text-base font-medium text-primary-700", {
              "text-green-700": data?.data.doneDoors >= 24,
            })}
          >
            {data?.data.doneDoors}/24
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 ">
          <div
            className={classNames("bg-primary-600 h-2.5 rounded-full", {
              "bg-green-600": data?.data.doneDoors >= 24,
            })}
            style={{ width: `${getPercent()}%` }}
          ></div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white min-h-screen pt-20 px-6">
      <section>
        <div className="mb-7">
          <ButtonLink to={`/calendars/${calendarId}`}>
            <RiArrowGoBackLine className="inline-block mr-2" />
            <span>Back to Calendar</span>
          </ButtonLink>
        </div>
        <p>Calendar Status</p>
        <FormSectionHeader />
        <DoorsReady />
      </section>
      <section>
        <div className="pt-4">
          <p>Calendar Data</p>
          <FormSectionHeader />
          <form onSubmit={onSubmit}>
            <div className="mb-6">
              <GenericInputField
                id="name"
                inputType="text"
                registerHandler={() =>
                  register("name", {
                    required: "Please enter a name for your calendar",
                    maxLength: {
                      value: 40,
                      message: "Name must be less than 40 characters",
                    },
                  })
                }
                errors={errors.name}
                defaultValue={data?.data?.name}
              >
                Name
              </GenericInputField>
            </div>
            <div className="mb-6">
              <GenericInputField
                id="description"
                inputType="textArea"
                registerHandler={() =>
                  register("description", {
                    required: "Please enter a description for your calendar",
                  })
                }
                errors={errors.description}
                defaultValue={data?.data?.description}
              >
                Description
              </GenericInputField>
            </div>
            <div className="mb-6 ">
              <label htmlFor="background" className="block mb-2 text-sm font-medium">
                Background Picture
              </label>
              <div className="grid grid-cols-2 justify-items-center items-center">
                <img src={backgroundUrl} alt="calendar background picture" />
                <label>
                  <input
                    type="file"
                    id="background"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target && e.target.files ? e.target.files[0] : null
                      file ? setBackgroundFile(file) : setBackgroundFile(null)
                    }}
                  />
                  <a>Change</a>
                </label>
              </div>
              <div className="my-6">
                <SubmitButton
                  onClick={() => handleSubmit}
                  fullWidth
                  isLoading={calendarMutations.update.isLoading || assetMutations.create.isLoading}
                >
                  Save Changes
                </SubmitButton>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section>
        <div className="py-4">
          <p>Calendar Management</p>
          <FormSectionHeader />
          <ButtonLink to={`/calendars/${calendarId}/access`} className="block">
            Manage Access
          </ButtonLink>

          <FormDivider />
          <ButtonLink to={`/calendars/${calendarId}/doorManagement`} className="block">
            Manage Doors
          </ButtonLink>
          <FormDivider />
          <ButtonLink to={`/calendars/${calendarId}/message`} className="block">
            Send Email to Users
          </ButtonLink>

          <FormDivider />
          <SubmitButton onClick={handleDelete} className="bg-red-700 hover:bg-red-500" fullWidth>
            Delete Calendar
          </SubmitButton>
        </div>
      </section>
    </div>
  )
}

export default CalendarEdit
