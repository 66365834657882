import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import ReactPlayer from "react-player"
import { Door as DoorType } from "../types/calendar"
import { FunctionComponent, useEffect } from "react"
import { useDoorMutationAPI } from "../hooks/api/useDoorsAPI"
import { Alert } from "flowbite-react"
import { ButtonLink, SubmitButton } from "./Buttons"
import { RiArrowGoBackLine } from "react-icons/ri"
import { toast } from "react-toastify"

interface DoorProps {
  door: DoorType
}

const Door: FunctionComponent<DoorProps> = (props) => {
  const { user } = useAuth()
  const { door } = props
  const doorMutations = useDoorMutationAPI(door.id)
  const navigate = useNavigate()
  const submit = () => {
    if (window.confirm("Are you sure you want to submit? You won't be able to edit this door after this.")) {
      doorMutations.ready.mutate({ isReady: true })
    }
  }
  const isSubmittable =
    door.ownerImg.url !== "" &&
    door.ownerName !== "" &&
    door.title !== "" &&
    door.artist !== "" &&
    door.body !== "" &&
    door.mediaUrl !== ""

  useEffect(() => {
    if (doorMutations.ready.isSuccess) {
      toast.success("Door submitted!")
      navigate("/")
    }
  }, [doorMutations.ready.isSuccess])

  return (
    <div className="pt-16 px-4 grid gap-6 bg-white dark:bg-gray-900 min-h-screen pb-6">
      <Link
        to={door.calendarId === "demo" ? "/demo" : `/calendars/${door.calendarId}`}
        className="ml-2 mt-5 py-2.5 px-6 rounded-lg border border-gray-900 hover:border-primary-700 bg-transparent text-center text-xs font-medium text-gray-900 hover:text-white hover:bg-primary-800  focus:ring-4 focus:ring-blue-300 dark:border-gray-800 dark:text-white md:w-fit"
      >
        <RiArrowGoBackLine className="inline-block mr-2" />
        Back to Calendar
      </Link>
      {!door.isReady && door.ownerId === user?.id && isSubmittable && (
        <Alert
          color="info"
          additionalContent={
            <>
              <div className="mt-2 mb-4 text-sm text-blue-700 dark:text-blue-800">
                Please submit your door once you are done so your calendar organizer has a better overview. You won't be
                able to edit this door afterwards. On request, your calendar organizer can enable you to edit your door
                again.
              </div>
              <div className="flex">
                <SubmitButton onClick={submit}>Submit</SubmitButton>
                <Link
                  to="edit"
                  className="ml-2 py-2.5 px-6 block rounded-lg border border-blue-700 bg-transparent text-center text-xs font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:ring-4 focus:ring-blue-300 dark:border-blue-800 dark:text-blue-800 dark:hover:text-white"
                >
                  Edit
                </Link>
              </div>
            </>
          }
        >
          <h3 className="text-lg font-medium text-blue-700 dark:text-blue-800">Great, almost done!</h3>
        </Alert>
      )}
      {!door.isReady && door.ownerId === user?.id && !isSubmittable && (
        <Alert
          color="info"
          additionalContent={
            <>
              <div className="mt-2 mb-4 text-sm text-blue-700 dark:text-blue-800">
                To complete your door and to make your organizer happy, please complete your door and submit it.
              </div>
              <div className="flex">
                <ButtonLink to={`edit`}>Edit Door</ButtonLink>
              </div>
            </>
          }
        >
          <h3 className="text-lg font-medium text-blue-700 dark:text-blue-800">This is just a preview!</h3>
        </Alert>
      )}
      <div className="grid md:inline-block md:mt-4 md:mx-auto">
        <div className="p-6 md:p-0 md:inline">
          <img
            src={door.ownerImg.url}
            className="object-cover bg-red-700 rounded-full p-5 md:p-3 w-full h-full aspect-square md:w-48 md:h-48 md:inline md:mx-6"
            alt="avatar"
          />
        </div>

        <div className="text-gray-900 dark:text-white text-3xl text-center md:text-8xl md:inline md:align-middle">
          {door.ownerName}
        </div>
      </div>

      <div className="text-gray-900 dark:text-white text-2xl md:text-6xl text-center">
        {door.artist}
        {door.artist && door.title && ": "}
        {door.title}
      </div>

      <div className="text-gray-500  dark:text-gray-400 text-center md:text-2xl whitespace-pre-line">{door.body}</div>

      <div className="relative pt-[56.25%]">
        <ReactPlayer style={{ position: "absolute", top: 0, left: 0 }} url={door.mediaUrl} width="100%" height="100%" />
      </div>
    </div>
  )
}

export default Door
