import { ReactNode } from "react"
import { FieldError, UseFormRegisterReturn } from "react-hook-form"
import PropTypes from "prop-types"

type InputType = "text" | "email" | "password" | "textArea" | "number"

interface GenericInputFieldProps {
  id: string
  inputType: InputType
  children: ReactNode
  registerHandler(): UseFormRegisterReturn
  placeholder?: string
  defaultValue?: string
  rows?: number
  errors?: FieldError
}

export const GenericInputField: React.FC<GenericInputFieldProps> = (props) => {
  const { id, inputType, children, registerHandler, placeholder, defaultValue, rows, errors } = props
  const fieldError = (message: string) => {
    return <div className="text-red-500 text-sm">{message}</div>
  }

  const labelClasses = "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
  const labelErrorClasses = "block mb-2 text-sm font-medium text-red-700 dark:text-red-500"

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"

  const inputErrorClasses =
    "bg-red-50 border border-red-500 text-red-900 dark:text-red-400 placeholder-red-700 text-sm rounded-lg focus:ring-blue-500 dark:bg-gray-700 focus:border-blue-500 block w-full p-2.5 "

  if (inputType === "textArea") {
    return (
      <div>
        <label htmlFor={id} className={errors ? labelErrorClasses : labelClasses}>
          {children}
        </label>
        <textarea
          {...registerHandler()}
          id={id}
          rows={rows}
          className={errors ? inputErrorClasses : inputClasses}
          placeholder={placeholder}
          defaultValue={defaultValue}
          aria-invalid={errors ? "true" : "false"}
        ></textarea>
        {errors?.message && fieldError(errors.message)}
      </div>
    )
  }

  return (
    <div>
      <label htmlFor={id} className={errors ? labelErrorClasses : labelClasses}>
        {children}
      </label>
      <input
        {...registerHandler()}
        type={inputType}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={errors ? inputErrorClasses : inputClasses}
        id={id}
        aria-invalid={errors ? "true" : "false"}
      />

      {errors?.message && fieldError(errors.message)}
    </div>
  )
}

GenericInputField.propTypes = {
  id: PropTypes.string.isRequired,
  inputType: PropTypes.oneOf<InputType>(["text", "email", "password", "textArea", "number"]).isRequired,
  children: PropTypes.node.isRequired,
  registerHandler: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  rows: PropTypes.number,
  errors: PropTypes.any,
}

export const FormDivider: React.FC = () => {
  return <div className="border-b border-gray-300 dark:border-gray-600 my-6"></div>
}
export const FormSectionHeader: React.FC = () => {
  return <div className="border-b border-gray-300 dark:border-gray-600 mb-6"></div>
}
