import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import bg from "../assets/bg.jpg"
import { Asset } from "../types/asset"

interface CalendarCardProps {
  calendar: {
    id: string
    name: string
    background: Asset
    isOwner: boolean
    organizer: string
  }
}

const CalendarCard: FunctionComponent<CalendarCardProps> = ({ calendar }) => {
  const { id, name, background, isOwner, organizer } = calendar
  return (
    <>
      <Link
        to={`/calendars/${id}`}
        className="flex flex-col items-center bg-white rounded-lg border shadow-md sm:flex-row  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
      >
        <img
          className="object-cover w-full h-32 rounded-t-lg sm:h-auto sm:w-1/2 sm:rounded-none sm:rounded-l-lg"
          src={background && background.url != "" ? background.url : bg}
          alt=""
        />
        <div className="flex flex-col justify-center items-center p-4 leading-normal sm:w-1/2">
          <h5 className="mb-2 text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{name}</h5>
          <h2 className="mb-2 text-md sm:text-lg font-light tracking-tight text-gray-900 dark:text-white">
            Organizer: {isOwner ? "You" : organizer}
          </h2>
        </div>
      </Link>
    </>
  )
}

export default CalendarCard
