import React from "react"
import { FunctionComponent, useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import avatar from "../assets/avatar.jpg"
import { Avatar, DarkThemeToggle, Dropdown } from "flowbite-react"
import { useUserMutationAPI } from "../hooks/api/useUserAPI"
import { toast } from "react-toastify"
import { ButtonLink } from "./Buttons"
import { ROUTES_WITHOUT_NAVIGATION } from "../App"
import { matchesOneInList } from "../utils/matchRegexList"
interface NavbarProps {
  dark?: boolean
}
import { RiPaypalLine } from "react-icons/ri"

export const Navbar: FunctionComponent<NavbarProps> = (props) => {
  const { user, logout } = useAuth()
  const { dark } = props
  const navigate = useNavigate()
  const location = useLocation()
  const classNames = `flex justify-between border-gray-200 p-4 lg:px-14 fixed top-0 w-full max-w-[1400px] transition-all z-50 ${
    dark ? "bg-white dark:bg-gray-800" : "bg-transparent"
  }`

  const isCalendarPage =
    matchesOneInList([/calendars\/./g], location.pathname) && !matchesOneInList([/calendars\/.*\//g], location.pathname)

  const userMutations = useUserMutationAPI()
  const onLogout = () => {
    logout()
    navigate("/", { replace: true })
  }

  const onNewsletterToggle = () => {
    userMutations.toggle.mutate({ newsletter: !user?.newsletter })
  }

  useEffect(() => {
    if (userMutations.toggle.isLoading) {
      toast.success("Changing newsletter settings...")
    }
  }, [userMutations.toggle.isLoading])

  // TODO: find better way for async auth context update.
  useEffect(() => {
    if (userMutations.toggle.isSuccess || userMutations.toggle.isError) {
      window.location.reload()
    }
  }, [userMutations.toggle.isSuccess, userMutations.toggle.isError])

  const UserMenu: FunctionComponent = () => {
    return (
      <div className="flex items-center md:order-2">
        {/*  <!-- Dropdown menu --> */}
        <DarkThemeToggle className="mr-4" />
        <Dropdown arrowIcon={false} inline={true} label={<Avatar alt="User menu" img={avatar} rounded={true} />}>
          <Dropdown.Header>
            <span className="block text-sm">{user?.name}</span>
            <span className="block truncate text-sm font-medium">{user?.username}</span>
          </Dropdown.Header>

          <Dropdown.Item>
            <span className="mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">Newsletter:</span>
            <label htmlFor="green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                type="checkbox"
                value=""
                id="green-toggle"
                className="sr-only peer"
                checked={user ? user?.newsletter : false}
                onChange={onNewsletterToggle}
                disabled={userMutations.toggle.isLoading}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-500 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            </label>
          </Dropdown.Item>
          <div>
            <Dropdown.Item>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=YHGY53LFAFPSJ"
                target={"_blank"}
                className="w-full"
                rel="noreferrer"
              >
                Donate <RiPaypalLine className="inline-block ml-2" />
              </a>
            </Dropdown.Item>
          </div>
          <button className="w-full" aria-label="logout button" onClick={onLogout}>
            <Dropdown.Item>Sign out</Dropdown.Item>
          </button>
        </Dropdown>
      </div>
    )
  }
  return (
    <>
      {matchesOneInList(ROUTES_WITHOUT_NAVIGATION, location.pathname) === false && (
        <nav className={classNames}>
          <Link to="/" className="flex items-center justify-center ">
            <span
              className={`text-md ${
                isCalendarPage ? "text-white" : "text-gray-900"
              } dark:text-white sm:text-xl font-semibold whitespace-nowrap`}
            >
              Julr
            </span>
          </Link>
          <div className="flex">
            {!user ? (
              <>
                <DarkThemeToggle />
                <ButtonLink to="/login" className="ml-2">
                  Login
                </ButtonLink>
              </>
            ) : (
              <>
                <UserMenu />
              </>
            )}
          </div>
        </nav>
      )}
    </>
  )
}
