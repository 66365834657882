import { Link, useLocation } from "react-router-dom"
import DoorCard from "../components/DoorCard"
import Snowfall from "react-snowfall"
import bg from "../assets/bg.jpg"
import { useAuth } from "../contexts/AuthContext"

import { Calendar as CalendarType } from "../types/calendar"
import { FunctionComponent, useState } from "react"
import { ButtonLink } from "./Buttons"
import { Alert } from "flowbite-react"
import PlaceholderDoorCard from "./PlaceholderDoorCard"

interface CalendarProps {
  calendar: CalendarType
}

const Calendar: FunctionComponent<CalendarProps> = (props) => {
  const location = useLocation()
  const { user } = useAuth()
  const { calendar } = props
  const [showAlert, setShowAlert] = useState(true)

  const doorOrder = [5, 10, 15, 23, 1, 17, 3, 6, 12, 21, 20, 9, 19, 24, 13, 22, 4, 8, 18, 7, 2, 16, 14, 11]

  // Sort the calendar doors day by the doorOrder array and remove all doors where day = 0
  const sortedDoors = calendar.doors
    .sort((a, b) => doorOrder.indexOf(a.day) - doorOrder.indexOf(b.day))
    .filter((door) => door.day !== 0)

  return (
    <div
      className="pb-16 min-h-screen"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)), url(${
          calendar && calendar?.background.url != "" ? calendar.background.url : bg
        })`,
      }}
    >
      <Snowfall
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: 2,
        }}
      />
      {/* Hero container */}
      <div className="pt-8 flex justify-center flex-col items-center">
        {calendar?.ownerId === user?.id && (
          <Alert
            color="info"
            className={`m-4 mt-10 ${showAlert ? "block" : "hidden"}`}
            onDismiss={() => setShowAlert(false)}
            additionalContent={
              <>
                <div className="mt-2 mb-4 text-sm text-blue-700 dark:text-blue-800">
                  Hi, {user?.name}! Since you are the organizer of this calendar, you will always see the whole
                  calendar. For everyone else, the doors will be hidden until the day of the door arrives.
                </div>
              </>
            }
          >
            <h3 className="text-lg font-medium text-blue-700 dark:text-blue-800">Preview View</h3>
          </Alert>
        )}
        {/* Hero Title */}
        <div className="text-5xl text-center font-bold mt-12 mb-6">
          <p>{calendar?.name}</p>
        </div>
        {calendar?.ownerId === user?.id && (
          <ButtonLink to={`edit`} className="mb-6 sm:w-auto">
            Calendar Settings
          </ButtonLink>
        )}

        {/* Hero description */}
        <div className=" font-medium  text-center text-lg mb-4 whitespace-pre-line px-8">
          <p>{calendar?.description}</p>
        </div>
      </div>
      {/* Calendar */}
      <div className="py-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-16 justify-items-center">
        {sortedDoors.map((door) => {
          if (door.isReady && door.ownerId !== "") {
            return (
              <Link
                to={
                  location.pathname === "/demo" || location.pathname === "/demo/"
                    ? `/demo/${door.id}`
                    : `/doors/${door.id}`
                }
                key={door.id}
              >
                <DoorCard day={door.day} ownerImg={door.ownerImg.url} />
              </Link>
            )
          }
          return <PlaceholderDoorCard day={door.day} key={door.id} />
        })}
      </div>
    </div>
  )
}

export default Calendar
