import React from "react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useParams, useNavigate, Link } from "react-router-dom"
import { toast } from "react-toastify"
import { passwordResetNewPassword, PasswordResetNewPasswordForm } from "../AuthProvider"
import Alert from "../components/Alert"
import { SubmitButton } from "../components/Buttons"
import { GenericInputField } from "../components/FormElements"
import { useAsync } from "../hooks/useAsync"
import bg from "../assets/bg.jpg"

const PasswordResetNewPassword = () => {
  const { resetToken } = useParams<{ resetToken: string }>()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetNewPasswordForm>({ mode: "onSubmit" })

  const { run, isLoading, isSuccess, error } = useAsync()
  const navigate = useNavigate()

  const onSubmit = handleSubmit((formData) => {
    run(
      passwordResetNewPassword({
        password: formData.password,
        resetToken: resetToken ? resetToken : "",
      })
    )
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success("New password saved!")
      navigate("/login")
    }
  })

  return (
    <section
      style={{ backgroundImage: `url(${bg})` }}
      className={` bg-no-repeat bg-cover bg-center min-h-screen bg-gray-700 bg-blend-multiply bg-opacity-60`}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
        <Link to="/" className="flex items-center mb-6 text-2xl md:text-4xl  font-semibold text-white">
          Julr
        </Link>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Change Password
          </h2>
          <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
            <div>
              <GenericInputField
                id="password"
                inputType="password"
                registerHandler={() =>
                  register("password", {
                    required: "Please enter your password",
                    validate: (value) => {
                      if (value.length < 8) {
                        return "Password must be at least 8 characters long"
                      }
                    },
                  })
                }
                errors={errors.password}
              >
                New Password
              </GenericInputField>
            </div>

            {error && error.response?.status === 401 && (
              <Alert type="error">Your reset link is expired or was used already. Please request a new one.</Alert>
            )}
            {error && error.response?.status === 500 && (
              <Alert type="error">
                Server error during password reset. Please try another try again later or contact the support.
              </Alert>
            )}
            <SubmitButton isLoading={isLoading} onClick={onSubmit} fullWidth>
              Change Password
            </SubmitButton>
          </form>
        </div>
      </div>
    </section>
  )
}

export default PasswordResetNewPassword
