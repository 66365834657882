import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Alert from "../components/Alert"
import { ButtonLink, SubmitButton } from "../components/Buttons"
import { GenericInputField } from "../components/FormElements"
import { SendCalendarMessageRequest, useCalendarMutationAPI } from "../hooks/api/useCalendarsAPI"
import Select from "react-select"
import { RiArrowGoBackLine } from "react-icons/ri"

const CalendarMessages = () => {
  const selectOptions = [
    { value: "viewers", label: "All viewers" },
    { value: "owners", label: "All door owners" },
    { value: "unfinished", label: "Only unfinished door owners" },
  ]
  const { calendarId } = useParams<{ calendarId: string }>()

  const calendarMutations = useCalendarMutationAPI(calendarId)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SendCalendarMessageRequest>({ mode: "onSubmit" })

  const onSubmit = handleSubmit(async (formData) => {
    calendarMutations.message.mutate(formData)
  })

  const { isLoading, isSuccess, isError, error } = calendarMutations.message

  useEffect(() => {
    if (isSuccess) {
      toast.success("Message send successfully")
      navigate(`/calendars/${calendarId}/edit`)
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      toast.error("Error sending message, please try again")
    }
  }, [isError])

  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white pt-20 px-6 pb-6 min-h-screen">
      <form onSubmit={onSubmit}>
        <div className="mb-7">
          <ButtonLink to={`/calendars/${calendarId}/edit`}>
            <RiArrowGoBackLine className="inline-block mr-2" />
            <span>Back to Calendar Settings</span>
          </ButtonLink>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium" htmlFor="recipients">
            Recipients
          </label>
          <Controller
            name="recipients"
            control={control}
            render={({ field }) => (
              <Select
                className="text-gray-900 w-full  "
                ref={field.ref}
                options={selectOptions}
                onChange={(val) => field.onChange(val?.value)}
                isSearchable={false}
              />
            )}
          />
        </div>
        <div className="mb-6">
          <GenericInputField
            inputType="textArea"
            rows={14}
            errors={errors.message}
            registerHandler={() => register("message", { required: true })}
            id="message"
          >
            Your Message
          </GenericInputField>
        </div>
        {isError && error.response?.status === 404 && (
          <Alert type="error">Sorry, something went wrong. The Email did not get send.</Alert>
        )}

        <SubmitButton onClick={onSubmit} isLoading={isLoading} fullWidth>
          Send Message
        </SubmitButton>
      </form>
    </div>
  )
}

export default CalendarMessages
