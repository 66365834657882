import React from "react"
import { ButtonLink } from "../components/Buttons"
import CalendarCard from "../components/CalendarCard"
import ToDoCard from "../components/ToDoCard"
import { useAuth } from "../contexts/AuthContext"
import { useCalendarQueryAPI } from "../hooks/api/useCalendarsAPI"
import { useDoorQueryAPI } from "../hooks/api/useDoorsAPI"
import config from "../utils/config"

const HomePage = () => {
  const { user } = useAuth()
  const { data: calendars } = useCalendarQueryAPI()
  const { data: doors } = useDoorQueryAPI()
  const emptyDoors = doors?.data.filter((door) => door.ownerName === "" && door.isReady === false)
  const unsubmittedDoors = doors?.data.filter((door) => door.ownerName !== "" && door.isReady === false)
  const calendarsWithUnassignedDoors = calendars?.data.filter(
    (calendar) =>
      calendar.unassignedDoors && calendar.unassignedDoors > 0 && calendar.viewerCount && calendar.viewerCount > 1
  )
  const calendarsWithUnfinishedDoors = calendars?.data.filter(
    (calendar) => calendar.doneDoors && calendar.doneDoors < 24
  )
  const calendarsWithNoViewers = calendars?.data.filter((calendar) => calendar.viewerCount && calendar.viewerCount <= 1)

  let todos = 0
  calendarsWithUnassignedDoors ? (todos += calendarsWithUnassignedDoors.length) : (todos += 0)
  calendarsWithUnfinishedDoors ? (todos += calendarsWithUnfinishedDoors.length) : (todos += 0)
  emptyDoors ? (todos += emptyDoors.length) : (todos += 0)
  unsubmittedDoors ? (todos += unsubmittedDoors.length) : (todos += 0)
  calendarsWithNoViewers ? (todos += calendarsWithNoViewers.length) : (todos += 0)

  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen pt-20 px-6">
      <h2 className="mb-4 text-2xl sm:text-4xl text-center tracking-tight font-extrabold text-gray-900 dark:text-white">
        {calendars && calendars.data.length === 0 ? "Welcome," : "Welcome back,"} {user?.name}!
      </h2>
      <section>
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6 ">
          <div className="h-full">
            <div className="text-xl font-bold sm:text-2xl flex justify-between items-center pb-4 text-gray-900 dark:text-white">
              <span>Your Calendars</span>

              <ButtonLink to={`/calendars/new`} className=" sm:w-auto px-5 py-2.5">
                New Calendar
              </ButtonLink>
            </div>
            <div className="grid gap-4">
              {calendars?.data.map((calendar) => <CalendarCard calendar={calendar} key={calendar.id} />)}
            </div>
          </div>
          <div className="h-full">
            <div className="text-xl font-bold sm:text-2xl py-4 lg:pt-0 text-gray-900 dark:text-white">
              <span>Your Todos {todos > 0 && `(${todos})`}</span>
            </div>
            <div className="grid gap-4">
              {emptyDoors &&
                emptyDoors?.length > 0 &&
                emptyDoors.map((door) => (
                  <ToDoCard
                    header={`Enter Door Details (${door.calendarName})`}
                    content={`The calendar organizer assigned a door to you. Now it's your time to contribute your song and story to the calendar and submit your door afterwards.`}
                    key={door.id}
                    linkId={`/doors/${door.id}/edit`}
                  />
                ))}
              {unsubmittedDoors &&
                unsubmittedDoors?.length > 0 &&
                unsubmittedDoors.map((door) => (
                  <ToDoCard
                    header={`Submit Your Door (${door.calendarName})`}
                    content={`You have started to enter your details for your door 🚀. Don't forget to finish and submit it to the calendar organizer.`}
                    key={door.id}
                    linkId={`/doors/${door.id}/edit`}
                  />
                ))}
              {calendarsWithUnassignedDoors &&
                calendarsWithUnassignedDoors?.length > 0 &&
                calendarsWithUnassignedDoors.map((calendar) => (
                  <ToDoCard
                    header={`Assign Doors (${calendar.name})`}
                    content={`It looks like you still have unassigned doors (${calendar.unassignedDoors}) in your calendar. Assign them to calendar viewers at the door management page, so they can contribute to the calendar.`}
                    key={calendar.id}
                    linkId={`/calendars/${calendar.id}/doormanagement`}
                  />
                ))}
              {calendarsWithUnfinishedDoors &&
                calendarsWithUnfinishedDoors?.length > 0 &&
                calendarsWithUnfinishedDoors.map((calendar) => (
                  <ToDoCard
                    header={`Finish Your Calendar (${calendar.name})`}
                    content={`You need to make sure, that all users have submitted their doors to you until the 1st of December. Currently ${calendar.doneDoors}/24 doors are marked as ready. Maybe send them a reminder email?`}
                    key={calendar.id}
                    linkId={`/calendars/${calendar.id}/message`}
                  />
                ))}
              {calendarsWithNoViewers &&
                calendarsWithNoViewers?.length > 0 &&
                calendarsWithNoViewers.map((calendar) => (
                  <ToDoCard
                    header={`Add people (${calendar.name})`}
                    content={`Nice! You have created a calendar but it looks like you're still all alone. You can add other people by their username or email address if they already have an account. You can also send them the calendar join link and the join password. Just click this card to get to the access management`}
                    key={calendar.id}
                    linkId={`/calendars/${calendar.id}/access`}
                  />
                ))}

              {todos === 0 && calendars && calendars.data.length !== 0 && (
                <span className="text-gray-900 dark:text-white">🚀You are all good!🚀</span>
              )}
              {calendars && calendars.data.length === 0 && (
                <ToDoCard
                  header={`Join or Create a Calendar`}
                  content={`Hi and thank you for joining Julr. If you are here because someone else is organizing a calendar, tell them your username (${user?.username}) so they can add you to their calendar or ask them to provide you with a join link and a join password. If you want to organize your own calendar, click here or the button above.`}
                  linkId={`/calendars/new`}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomePage
