import config from "./utils/config"
import dayjs from "dayjs"
import { jwtDecode } from "jwt-decode"
import axios from "axios"

const localStorageKeyAccessToken = config.localStorageKeyAccessToken
const localStorageKeyRefreshToken = config.localStorageKeyRefreshToken
const API_BASE_URL = config.apiBaseUrl

const authClient = axios.create({
  baseURL: API_BASE_URL,
})

export interface AuthTokens {
  access: string
  refresh: string
}

export interface LoginForm {
  email: string
  password: string
}

export interface RegisterForm {
  name: string
  email: string
  password: string
  newsletter: boolean
}

export interface PasswordResetNewPasswordForm {
  password: string
  resetToken: string
}

export const isTokenExpired = (token: string) => {
  const decodedToken = jwtDecode(token)
  const expiryTime = dayjs.unix(decodedToken.exp ? decodedToken.exp : 0)
  const isExpired = expiryTime.diff(dayjs()) < 1
  return isExpired
}

const refreshTokens = async (refreshToken: string) => {
  let response
  try {
    response = await authClient.post("users/login/refresh", { refresh: refreshToken })
  } catch (error) {
    console.warn("Authentication failed. Please login again.")
    clearTokens()
    return null
  }
  if (response.data.code === "token_not_valid") {
    clearTokens()
    return null
  }
  setTokens(response.data)

  return response.data as AuthTokens
}

const getTokens = () => {
  const authTokens = {
    access: window.localStorage.getItem(localStorageKeyAccessToken),
    refresh: window.localStorage.getItem(localStorageKeyRefreshToken),
  }
  if (authTokens.access === null || authTokens.refresh === null) {
    return null
  }

  return authTokens as AuthTokens
}

const clearTokens = () => {
  window.localStorage.removeItem(localStorageKeyAccessToken)
  window.localStorage.removeItem(localStorageKeyRefreshToken)
}

const setTokens = (authTokens: AuthTokens) => {
  window.localStorage.setItem(localStorageKeyAccessToken, authTokens.access)
  window.localStorage.setItem(localStorageKeyRefreshToken, authTokens.refresh)
}

const handleUserResponse = ({ access, refresh }: AuthTokens) => {
  setTokens({ access, refresh })
  return {
    access,
    refresh,
  }
}

const login = ({ email, password }: LoginForm) => {
  return authClient.post("users/login", { email, password }).then((res) => handleUserResponse(res.data))
}
const googleLogin = (accessToken: string) => {
  return authClient.post("users/login/google", { accessToken }).then((res) => handleUserResponse(res.data))
}
const microsoftLogin = (accessToken: string) => {
  return authClient.post("users/login/microsoft", { accessToken }).then((res) => handleUserResponse(res.data))
}
const register = ({ email, password, name, newsletter }: RegisterForm) => {
  return authClient.post("users/", { email, password, name, newsletter }).then((res) => handleUserResponse(res.data))
}

const logout = async () => {
  console.log("logout")
  const accessToken = window.localStorage.getItem(localStorageKeyAccessToken)
  if (accessToken) {
    try {
      await authClient.post("users/logout", {}, { headers: { Authorization: `Bearer ${accessToken}` } })
    } catch (error) {
      console.error("We have to log you out please try to authenticate again.")
    }
  }
  clearTokens()
}

const requestPasswordReset = ({ email }: { email: string }) => {
  return authClient.post("users/password-reset", { email })
}

const passwordResetNewPassword = ({ password, resetToken }: PasswordResetNewPasswordForm) => {
  return authClient.post("users/password", { password, resetToken })
}

export {
  getTokens,
  setTokens,
  login,
  googleLogin,
  microsoftLogin,
  logout,
  register,
  refreshTokens,
  requestPasswordReset,
  passwordResetNewPassword,
}
