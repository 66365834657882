import { Calendar, CalendarListResponse, CreateCalendarRequest } from "../../types/calendar"
import useAxios from "../../utils/useAxios"
import { useMutation, useQuery, UseQueryResult, useQueryClient } from "react-query"
import { useEffect } from "react"
import { AxiosError, AxiosResponse } from "axios"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { demoCalendar } from "../../demo/demoData"

export interface CalendarMutationsResponse extends CreateCalendarRequest {
  id: string
}

export interface AddCalendarViewersRequest {
  username: string
  email: string
}
export interface DeleteCalendarViewersRequest {
  viewerId: string
}

interface SortDoorsRequest {
  oldDay: number
  newDay: number
}

export interface SendCalendarMessageRequest {
  message: string
  recipients: string
}

export interface JoinCalendarRequest {
  password: string
}

const useCalendarQueryAPI = (): UseQueryResult<AxiosResponse<CalendarListResponse[]>> => {
  const apiClient = useAxios()
  const query = useQuery<AxiosResponse<CalendarListResponse[]>, AxiosError>(
    ["calendars"],
    () => apiClient.get("/calendars/"),
    {
      retry: false,
    }
  )
  return query
}

const useCalendarFetchAPI = (calendarId?: string): UseQueryResult<AxiosResponse<Calendar>> => {
  const apiClient = useAxios()
  const query = useQuery<AxiosResponse<Calendar, AxiosError>>(
    ["calendar", calendarId],
    () => apiClient.get(`/calendars/${calendarId}`),
    {}
  )

  if (calendarId === "demo" && query.data) {
    query.data.data = demoCalendar
  }

  return query
}

const useCalendarMutationAPI = <MutationData>(calendarId?: string) => {
  const apiClient = useAxios()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const createCalendar = useMutation<
    AxiosResponse<CalendarMutationsResponse>,
    AxiosError,
    CreateCalendarRequest,
    unknown
  >((data) => apiClient.post(`/calendars/`, data))

  useEffect(() => {
    if (createCalendar.isSuccess) {
      navigate(`/calendars/${createCalendar.data.data.id}`)
    }
  }, [createCalendar.isSuccess])

  const updateCalendar = useMutation<AxiosResponse<CalendarMutationsResponse>, AxiosError, MutationData, unknown>(
    (data) => apiClient.put(`/calendars/${calendarId}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["calendar", calendarId])
      },
    }
  )

  const addViewers = useMutation<AxiosResponse, AxiosError, AddCalendarViewersRequest, unknown>(
    (data) => apiClient.post(`/calendars/${calendarId}/add-viewers`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["calendar", calendarId])
      },
    }
  )

  const sortDoors = useMutation<AxiosResponse, AxiosError, SortDoorsRequest, unknown>(
    (data) => apiClient.patch(`/calendars/${calendarId}/sort`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["calendar", calendarId])
      },
    }
  )

  const sendCalendarMessage = useMutation<AxiosResponse, AxiosError, SendCalendarMessageRequest, unknown>((data) =>
    apiClient.post(`/calendars/${calendarId}/message`, data)
  )

  const joinCalendar = useMutation<AxiosResponse, AxiosError, JoinCalendarRequest, unknown>((data) =>
    apiClient.post(`/calendars/${calendarId}/join`, data)
  )

  const removeViewer = useMutation<AxiosResponse, AxiosError, DeleteCalendarViewersRequest, unknown>((data) =>
    apiClient.post(`/calendars/${calendarId}/remove-viewers`, data)
  )

  const generatePassword = useMutation(() => apiClient.post(`/calendars/${calendarId}/password`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["calendar", calendarId])
    },
  })
  const deleteCalendar = useMutation(() => apiClient.delete(`/calendars/${calendarId}`))

  useEffect(() => {
    if (
      updateCalendar.isSuccess ||
      addViewers.isSuccess ||
      sortDoors.isSuccess ||
      createCalendar.isSuccess ||
      generatePassword.isSuccess ||
      removeViewer.isSuccess ||
      deleteCalendar.isSuccess ||
      joinCalendar.isSuccess
    ) {
      queryClient.invalidateQueries("calendars")
      queryClient.invalidateQueries(["calendar", calendarId])
    }
    updateCalendar.isSuccess &&
      navigate(`/calendars/${updateCalendar.data.data.id ? updateCalendar.data.data.id : calendarId}`)
  }, [
    updateCalendar.isSuccess,
    addViewers.isSuccess,
    sortDoors.isSuccess,
    createCalendar.isSuccess,
    generatePassword.isSuccess,
    removeViewer.isSuccess,
    deleteCalendar.isSuccess,
    joinCalendar.isSuccess,
  ])

  useEffect(() => {
    if (
      updateCalendar.isError ||
      createCalendar.isError ||
      deleteCalendar.isError ||
      joinCalendar.isError ||
      addViewers.isError ||
      sortDoors.isError ||
      removeViewer.isError
    ) {
      toast.error(updateCalendar.error?.message)
    }
  }, [
    updateCalendar.isError,
    createCalendar.isError,
    deleteCalendar.isError,
    joinCalendar.isError,
    addViewers.isError,
    sortDoors.isError,
    removeViewer.isError,
  ])

  return {
    create: createCalendar,
    update: updateCalendar,
    addViewers: addViewers,
    sortDoors: sortDoors,
    message: sendCalendarMessage,
    join: joinCalendar,
    password: generatePassword,
    removeViewers: removeViewer,
    delete: deleteCalendar,
  }
}

export { useCalendarMutationAPI, useCalendarQueryAPI, useCalendarFetchAPI }
