import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { Navbar } from "./Navbar"

const Layout = () => {
  const [dark, setDark] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        setDark(true)
      } else {
        setDark(false)
      }
    })
  })

  return (
    <>
      <Navbar dark={dark} />
      <main className="max-w-[1400px] bg-black w-full min-h-screen">
        <Outlet />
      </main>
      {/* <Footer /> */}
    </>
  )
}

export default Layout
