import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import useAxios from "../../utils/useAxios"

interface NewsletterUpdateRequest {
  newsletter: boolean
}

const useUserMutationAPI = () => {
  const apiClient = useAxios()

  const toggleNewsletter = useMutation<AxiosResponse<null>, AxiosError, NewsletterUpdateRequest, unknown>((data) =>
    apiClient.patch(`users/me/newsletter`, data)
  )

  return {
    toggle: toggleNewsletter,
  }
}

export { useUserMutationAPI }
