import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

interface ToDoCardProps {
  header: string
  content: string
  linkId: string
}

const ToDoCard: FunctionComponent<ToDoCardProps> = (props) => {
  const { header, content, linkId: link } = props
  return (
    <>
      <Link
        to={link}
        className="block p-6  bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <h5 className="mb-2 text-lg sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{header}</h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">{content}</p>
      </Link>
    </>
  )
}

export default ToDoCard
