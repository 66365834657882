import { FunctionComponent, ReactNode } from "react"

type alertType = "info" | "error" | "success" | "warning" | "dark"

interface AlertProps {
  children: ReactNode
  type: alertType
}

const Alert: FunctionComponent<AlertProps> = (props) => {
  const { children, type } = props
  const alertClasses = "p-4 mb-4 text-sm"
  const infoClasses = `${alertClasses} text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"`
  const errorClasses = `${alertClasses} text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800`
  const successClasses = `${alertClasses} text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800`
  const warningClasses = `${alertClasses} text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800`
  const darkClasses = `${alertClasses} text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300`
  let classes

  switch (type) {
    case "info":
      classes = infoClasses
      break
    case "error":
      classes = errorClasses
      break
    case "success":
      classes = successClasses
      break
    case "warning":
      classes = warningClasses
      break
    case "dark":
      classes = darkClasses
      break
    default:
      classes = infoClasses
      break
  }

  return (
    <div className={classes} role="alert">
      <span className="font-medium">{children}</span>
    </div>
  )
}

export default Alert
