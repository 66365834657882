import { Door, MyDoors } from "../../types/calendar"
import useAxios from "../../utils/useAxios"
import { useMutation, useQuery, UseQueryResult, useQueryClient } from "react-query"
import { useEffect } from "react"
import { AxiosError, AxiosResponse } from "axios"
import { toast } from "react-toastify"

const useDoorFetchAPI = (doorId?: string): UseQueryResult<AxiosResponse<Door>> => {
  const apiClient = useAxios()
  const query = useQuery<AxiosResponse<Door, AxiosError>>(
    ["door", doorId],
    () => apiClient.get(`/calendars/doors/${doorId}`),
    {}
  )

  return query
}

const useDoorQueryAPI = (): UseQueryResult<AxiosResponse<MyDoors[]>> => {
  const apiClient = useAxios()
  const query = useQuery<AxiosResponse<MyDoors[], AxiosError>>("myDoors", () => apiClient.get(`/calendars/doors/`), {})

  return query
}

const useDoorMutationAPI = <MutationData>(doorId?: string) => {
  const apiClient = useAxios()
  const queryClient = useQueryClient()

  const updateDoor = useMutation<AxiosResponse<MutationData>, AxiosError, MutationData, unknown>(
    (data) => apiClient.put(`/calendars/doors/${doorId}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["door", doorId])
      },
    }
  )

  const doorReadyMutation = useMutation<AxiosResponse<MutationData>, AxiosError, MutationData, unknown>((data) =>
    apiClient.patch(`/calendars/doors/${doorId}/ready`, data)
  )

  const assignDoor = useMutation<AxiosResponse<MutationData>, AxiosError, MutationData, unknown>(
    (data) => apiClient.patch(`/calendars/doors/${doorId}/assign`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["doors", doorId])
        queryClient.invalidateQueries("myDoors")
      },
    }
  )

  useEffect(() => {
    if (updateDoor.isError || doorReadyMutation.isError || assignDoor.isError) {
      toast.error(updateDoor.error?.message)
    }
  }, [updateDoor.isError, doorReadyMutation.isError, assignDoor.isError])

  useEffect(() => {
    if (updateDoor.isSuccess || doorReadyMutation.isSuccess || assignDoor.isSuccess) {
      queryClient.invalidateQueries("calendars")
      queryClient.invalidateQueries("calendar")
      queryClient.invalidateQueries("myDoors")
      queryClient.invalidateQueries(["doors", doorId])
    }
  }, [updateDoor.isSuccess, doorReadyMutation.isSuccess, assignDoor.isSuccess])

  return { update: updateDoor, ready: doorReadyMutation, assignOwner: assignDoor }
}

export { useDoorFetchAPI, useDoorMutationAPI, useDoorQueryAPI }
