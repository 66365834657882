import dooPlaceholder from "../assets/doorPlaceholder.jpg"

const PlaceholderDoorCard = ({ day }: { day: number }) => {
  return (
    <div className="bg-red-700 relative w-60 h-60 rounded-2xl p-4 ">
      <img src={dooPlaceholder} alt="avatar" className="w-full h-full object-cover opacity-90" />
      <div className=" absolute z-10 -bottom-6 left-1/2 -translate-x-1/2 font-bold text-7xl">{day}</div>
    </div>
  )
}

export default PlaceholderDoorCard
