const Impressum = () => {
  return (
    <>
      <div className="flex flex-col mt-auto">
        <div>
          <div>Impressum </div>
          <span>Christian Oehne</span>
          <br />
          <span>Gießener Str. 89</span>
          <br />
          <span>51105 Köln</span>
          <br />
          <a href="mailto:support@oehne.de">support@oehne.de</a>
          <br />
          <span>tel: +49 461 57494458 </span>
        </div>
      </div>
    </>
  )
}

export default Impressum
