import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Alert from "../components/Alert"
import { ButtonLink, SubmitButton } from "../components/Buttons"
import { FormDivider, GenericInputField } from "../components/FormElements"
import { AddCalendarViewersRequest, useCalendarFetchAPI, useCalendarMutationAPI } from "../hooks/api/useCalendarsAPI"

import { RiRefreshLine, RiFileCopy2Line, RiCloseLine, RiArrowGoBackLine } from "react-icons/ri"
import config from "../utils/config"

const AccessManagement = () => {
  const { calendarId } = useParams<{ calendarId: string }>()
  const CALENDAR_JOIN_URL = `${config.appBaseUrl}/calendars/${calendarId}/join`

  const calendarMutations = useCalendarMutationAPI(calendarId)

  const { data } = useCalendarFetchAPI(calendarId)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddCalendarViewersRequest>({ mode: "onSubmit" })

  const onSubmit = handleSubmit(async (formData) => {
    calendarMutations.addViewers.mutate(formData)
  })

  const { isLoading, isSuccess, isError, error } = calendarMutations.addViewers

  const { isSuccess: isRemoveSuccess } = calendarMutations.removeViewers

  useEffect(() => {
    if (isSuccess) {
      toast.success("Viewer successfully added")
    }
  }, [isSuccess])
  useEffect(() => {
    if (isRemoveSuccess) {
      toast.success("Viewer successfully removed")
    }
  }, [isRemoveSuccess])

  const handleNewPassword = () => {
    if (
      window.confirm(
        "Are you sure you want to generate a new password? People with the old password will no longer be able to join the calendar."
      )
    ) {
      calendarMutations.password.mutate()
    }
  }
  const handleViewerRemoval = (viewerId: string) => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      calendarMutations.removeViewers.mutate({ viewerId })
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(CALENDAR_JOIN_URL)
    toast.success("Link copied to clipboard")
  }

  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white pt-20 px-6 pb-6 min-h-screen">
      <section>
        <div className="mb-7">
          <ButtonLink to={`/calendars/${calendarId}/edit`}>
            <RiArrowGoBackLine className="inline-block mr-2" />
            <span>Back to Calendar Settings</span>
          </ButtonLink>
        </div>
        <div>
          Users who can view the calendar ({data?.data?.viewers.length}
          ):
          <div className="w-full text-gray-900 bg-white rounded-lg border border-gray-200 mt-5">
            {data?.data.viewers.map((viewer) => (
              <button
                key={viewer.id}
                type="button"
                className="relative flex justify-between items-center py-2 px-4 w-full text-sm font-medium rounded-lg las:rounded-b-lg first:rounded-t-lg border-b border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-red-700"
              >
                {viewer.username} / {viewer.email}
                {data.data.ownerId !== viewer.id ? (
                  <RiCloseLine onClick={() => handleViewerRemoval(viewer.id)} />
                ) : null}
              </button>
            ))}
          </div>
        </div>
      </section>
      <section>
        <FormDivider />
        <div className="flex justify-between items-center mb-5  ">
          <a href={CALENDAR_JOIN_URL} target="_blank" className="cursor-text" rel="noreferrer">
            Calendar join link
          </a>
          <div className="inline-block cursor-pointer" onClick={handleCopyLink}>
            <span className="inline-block mr-2">Copy</span>
            <RiFileCopy2Line className="inline-block" />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span>Calendar password: {data?.data.password}</span>
          <div className="inline-block cursor-pointer" onClick={handleNewPassword}>
            <span className="inline-block mr-2">New</span>
            <RiRefreshLine className="inline-block" />
          </div>
        </div>
      </section>
      <FormDivider />
      <span>Add existing users</span>
      <form onSubmit={onSubmit}>
        <div className="my-6">
          <GenericInputField
            inputType="text"
            errors={errors.username}
            registerHandler={() => register("username")}
            id="username"
          >
            Add by Username
          </GenericInputField>
        </div>
        <div className="mb-6">
          <GenericInputField
            inputType="text"
            errors={errors.email}
            registerHandler={() => register("email")}
            id="email"
          >
            Add by Email
          </GenericInputField>
        </div>
        {isError && error.response?.status === 400 && (
          <Alert type="error">Please provide a valid username or email of an already registered user.</Alert>
        )}
        {isError && error.response?.status === 404 && (
          <Alert type="error">No user found with the provided username or email. Did they create an account yet?</Alert>
        )}
        {isError && error.response?.status === 409 && (
          <Alert type="error">The user already has access to this calendar.</Alert>
        )}
        <SubmitButton onClick={onSubmit} isLoading={isLoading} fullWidth>
          Add
        </SubmitButton>
      </form>
    </div>
  )
}

export default AccessManagement
