import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { FormDivider, GenericInputField } from "../components/FormElements"
import { RegisterForm } from "../AuthProvider"
import { useAsync } from "../hooks/useAsync"
import { useAuth } from "../contexts/AuthContext"
import Alert from "../components/Alert"
import { useEffect } from "react"
import { SubmitButton } from "../components/Buttons"
import bg from "../assets/bg.jpg"
import { GoogleButton, MsButton } from "../components/SocialButtons"

const Register = () => {
  const { run, error, isLoading, isSuccess } = useAsync<unknown>()
  const { register: registerApi, user } = useAuth()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>({ mode: "onSubmit" })

  const onSubmit = handleSubmit((formData) => {
    run(registerApi(formData))
  })

  useEffect(() => {
    if (isSuccess && user) {
      navigate("/calendars", { replace: true })
    }
  }, [isSuccess, user])

  return (
    <section
      style={{ backgroundImage: `url(${bg})` }}
      className={` bg-no-repeat bg-cover bg-center min-h-screen bg-gray-700 bg-blend-multiply bg-opacity-60`}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
        <Link to="/" className="flex items-center mb-6 text-2xl md:text-4xl font-semibold text-white">
          Julr
        </Link>
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
          <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
            <h2 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
              Create your Free Account
            </h2>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <GenericInputField
                  id="name"
                  inputType="text"
                  registerHandler={() =>
                    register("name", {
                      required: "Please enter your name",
                      maxLength: {
                        value: 20,
                        message: "Please enter a name with fewer than 22 characters. Your first name is enough!",
                      },
                    })
                  }
                  errors={errors.name}
                >
                  Your First Name
                </GenericInputField>
              </div>
              <div>
                <GenericInputField
                  id="email"
                  inputType="email"
                  registerHandler={() =>
                    register("email", {
                      required: "Please enter your email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })
                  }
                  errors={errors.email}
                >
                  Your Email
                </GenericInputField>
              </div>
              <div>
                <GenericInputField
                  id="password"
                  inputType="password"
                  registerHandler={() =>
                    register("password", {
                      required: "Please enter your password",
                      validate: (value) => {
                        if (value.length < 8) {
                          return "Password must be at least 8 characters long"
                        }
                      },
                    })
                  }
                  errors={errors.password}
                >
                  Your Password
                </GenericInputField>
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    defaultChecked
                    id="newsletter"
                    aria-describedby="newsletter"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    {...register("newsletter")}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="newsletter" className="font-light text-gray-500 dark:text-gray-300">
                    Send me occasional emails about new features.
                  </label>
                </div>
              </div>

              {error && error.response?.status === 400 && error.response.data === "user already exists" && (
                <Alert type="error">
                  This email is already registered. Please try to {""}
                  <Link to={"/login"} className="underline">
                    login
                  </Link>{" "}
                  instead.
                </Alert>
              )}
              <SubmitButton onClick={onSubmit} isLoading={isLoading} fullWidth>
                Create an account
              </SubmitButton>
              <FormDivider />
              <GoogleButton />
              <MsButton />
              <p className="text-sm text-center">
                <Link to="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                  Already have an account?
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Register
