import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { AuthProvider, useAuth } from "./contexts/AuthContext"
import { QueryClient, QueryClientProvider, useQueryErrorResetBoundary } from "react-query"
import { Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { ToastContainer } from "react-toastify"
import { ReactQueryDevtools } from "react-query/devtools"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { MsalProvider } from "@azure/msal-react"
import { Configuration, PublicClientApplication } from "@azure/msal-browser"
import { demoCalendar } from "./demo/demoData"
import { Flowbite } from "flowbite-react"
import { BrowserTracing } from "@sentry/tracing"
import * as Sentry from "@sentry/react"
import ScrollToTop from "./utils/ScrollToTop"
import FullPageError404 from "./components/FullPageError404"
import FullPageSpinner from "./components/FullPageSpinner"
import Login from "./pages/Login"
import Layout from "./components/Layout"
import Home from "./pages/Home"
import Register from "./pages/Register"
import CalendarNew from "./pages/CalendarNew"
import CalendarPage from "./pages/CalendarPage"
import CalendarEdit from "./pages/CalendarEdit"
import DoorEdit from "./pages/DoorEdit"
import DoorPage from "./pages/DoorPage"
import AccessManagement from "./pages/AccessManagement"
import DoorManagement from "./pages/DoorManagement"
import CalendarMessages from "./pages/CalendarMessages"
import CalendarJoin from "./pages/CalendarJoin"
import Impressum from "./pages/Impressum"
import DataPrivacy from "./pages/DataPrivacy"
import PasswordReset from "./pages/PasswordReset"
import PasswordResetNewPassword from "./pages/PasswordResetNewPassword"
import Calendar from "./components/Calendar"
import HomePage from "./pages/HomePage"
import config from "./utils/config"

export const ROUTES_WITHOUT_NAVIGATION = [/login/g, /register/g, /reset\/.*/g]

// React Query configuration
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: true,
      suspense: true,
    },
  },
})
// Sentry configuration
Sentry.init({
  dsn: "https://cf21f4874f1f4768afd7a70480e5bd31@o1399210.ingest.sentry.io/6726422",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
})

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: config.microsoftOAuthClientId,
    redirectUri: "/login",
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
}

const pca = new PublicClientApplication(configuration)

const App = () => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<FullPageSpinner />}>
          <BrowserRouter>
            <ErrorBoundary
              onReset={reset}
              fallbackRender={({ resetErrorBoundary }) => (
                <div>
                  Etwas ist schief gelaufen!
                  <button onClick={() => resetErrorBoundary()}>Versuche es nochmal</button>
                </div>
              )}
            >
              <MsalProvider instance={pca}>
                <Flowbite>
                  <GoogleOAuthProvider clientId={config.googleOAuthClientId}>
                    <ScrollToTop />
                    <Routes>
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="impressum" element={<Impressum />} />
                        <Route path="dataprivacy" element={<DataPrivacy />} />
                        <Route path="demo" element={<Calendar calendar={demoCalendar} />} />
                        <Route path="demo/:doorId" element={<DoorPage />} />
                        <Route path="register" element={<Register />} />
                        <Route path="login" element={<Login />} />
                        <Route path="reset" element={<PasswordReset />} />
                        <Route path="reset/:resetToken" element={<PasswordResetNewPassword />} />
                        <Route path="calendars">
                          <Route
                            path=":calendarId/doorManagement"
                            element={
                              <RequireAuth>
                                <DoorManagement />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path=":calendarId/access"
                            element={
                              <RequireAuth>
                                <AccessManagement />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path=":calendarId/message"
                            element={
                              <RequireAuth>
                                <CalendarMessages />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path=":calendarId/edit"
                            element={
                              <RequireAuth>
                                <CalendarEdit />
                              </RequireAuth>
                            }
                          />

                          <Route
                            path=":calendarId/join"
                            element={
                              <RequireAuth>
                                <CalendarJoin />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path=":calendarId"
                            element={
                              <RequireAuth>
                                <CalendarPage />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="new"
                            element={
                              <RequireAuth>
                                <CalendarNew />
                              </RequireAuth>
                            }
                          />
                          <Route index element={<HomePage />} />
                        </Route>
                        <Route path="doors">
                          <Route
                            path=":doorId"
                            element={
                              <RequireAuth>
                                <DoorPage />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path=":doorId/edit"
                            element={
                              <RequireAuth>
                                <DoorEdit />
                              </RequireAuth>
                            }
                          />
                        </Route>
                        <Route path="*" element={<FullPageError404 />} />
                      </Route>
                    </Routes>
                  </GoogleOAuthProvider>
                </Flowbite>
              </MsalProvider>
            </ErrorBoundary>
            <ToastContainer limit={2} position="bottom-right" />
            <ReactQueryDevtools initialIsOpen={false} />
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </AuthProvider>
  )
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export default App
