import { useLocation, useParams } from "react-router-dom"
import Door from "../components/Door"

import { demoDoors } from "../demo/demoData"
import { useDoorFetchAPI } from "../hooks/api/useDoorsAPI"

const DoorPage = () => {
  const { doorId } = useParams<{ doorId: string }>()
  const location = useLocation()
  const doorIdNumber = parseInt(doorId as unknown as string)

  if (location.pathname.includes("demo") && doorIdNumber && doorIdNumber > 0 && doorIdNumber < 25) {
    return <Door door={demoDoors[doorIdNumber - 1]} />
  }

  const { data, isSuccess } = useDoorFetchAPI(doorId)

  const doorData = data?.data

  if (isSuccess && doorData) {
    return <Door door={doorData} />
  }

  return <div>Something went wrong...</div>
}

export default DoorPage
