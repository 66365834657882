import { useEffect } from "react"
import { RiArrowGoBackLine } from "react-icons/ri"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { ButtonLink } from "../components/Buttons"
import DoorCardEdit from "../components/DoorCardEdit"
import { FormSectionHeader } from "../components/FormElements"
import { useCalendarFetchAPI, useCalendarMutationAPI } from "../hooks/api/useCalendarsAPI"
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd"

const DoorManagement = () => {
  const { calendarId } = useParams<{ calendarId: string }>()
  const { data } = useCalendarFetchAPI(calendarId)
  const sortedDoors = data?.data.doors.sort((a, b) => a.rank - b.rank)

  const calendarMutations = useCalendarMutationAPI(calendarId)

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result
    if (!destination) return
    if (destination.index === source.index) return
    const oldRank = source.index + 1
    const newRank = destination.index + 1
    if (oldRank !== newRank) {
      calendarMutations.sortDoors.mutate({ oldDay: oldRank, newDay: newRank })
    }
  }

  const { isSuccess, isError, error } = calendarMutations.sortDoors

  useEffect(() => {
    if (isSuccess) {
      toast.success("Door day successfully changed", { autoClose: 1000 })
    }
    if (isError) {
      toast.error(error.response?.data as string)
    }
  }, [isSuccess, isError])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white px-6 pt-20 pb-6 min-h-screen">
        <section>
          <div className="mb-7">
            <ButtonLink to={`/calendars/${calendarId}/edit`}>
              <RiArrowGoBackLine className="inline-block mr-2" />
              <span>Back to Calendar Settings</span>
            </ButtonLink>
          </div>
          <div>
            <p> Door Management</p> <FormSectionHeader />
            <div
              className={`border border-gray-300 dark:border-gray-700
           rounded-lg p-2 my-2 text-sm flex justify-between items-center text-gray-900 dark:bg-gray-800 dark:text-white`}
            >
              <span>Rank</span>
              <span>Day</span>
              <span>Owner</span>
              <span>Ready</span>
            </div>
          </div>
        </section>

        <Droppable droppableId={"droppableId"}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {data &&
                sortedDoors?.map((door, index) => (
                  <DoorCardEdit key={door.id} door={door} index={index} viewers={data?.data.viewers} />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}

export default DoorManagement
