import { FunctionComponent } from "react"
import Avatar from "../assets/avatar.jpg"

interface DoorCardProps {
  day: number
  ownerImg: string
}

const DoorCard: FunctionComponent<DoorCardProps> = (props) => {
  const { day, ownerImg } = props
  return (
    <div className="bg-red-700 relative w-60 h-60 rounded-2xl p-4 ">
      <img src={ownerImg ? ownerImg : Avatar} alt="avatar" className="w-full h-full object-cover" />
      <div className=" absolute z-10 -bottom-6 left-1/2 -translate-x-1/2 font-bold text-7xl">{day}</div>
    </div>
  )
}

export default DoorCard
