import { useParams } from "react-router-dom"

import Calendar from "../components/Calendar"
import { demoCalendar } from "../demo/demoData"
import { useCalendarFetchAPI } from "../hooks/api/useCalendarsAPI"

const CalendarPage = () => {
  const { calendarId } = useParams<{ calendarId: string }>()

  if (calendarId === "demo") {
    return <Calendar calendar={demoCalendar} />
  }

  const { data, isSuccess } = useCalendarFetchAPI(calendarId)

  const calendarData = data?.data

  if (isSuccess && calendarData) {
    return <Calendar calendar={calendarData} />
  }

  return <div>Something went wrong...</div>
}

export default CalendarPage
