import { Accordion } from "flowbite-react"
import { Link } from "react-router-dom"
import hero from "../assets/hero.jpg"
import Footer from "../components/Footer"
import lp1 from "../assets/lp1.jpg"
import lp2 from "../assets/lp2.jpg"

const LandingPage = () => {
  return (
    <div className="bg-white dark:bg-gray-900">
      <section>
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-12 xl:gap-0 lg:pt-24 lg:pb-0 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7 xl:col-span-8">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-gray-900 dark:text-white">
              The Collaborative Advent Calendar Experience
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Build your own advent calendar with your friends, family or coworkers. Still don't know what this could
              look like? Take a look at our{" "}
              <Link className="text-primary-600 hover:underline dark:text-primary-500" to={"/demo"}>
                demo calendar
              </Link>
              .
            </p>

            <div className="flex items-center mb-3">
              <div>
                <Link
                  to={"/register"}
                  className="px-5 py-3 text-sm font-medium text-center text-white rounded-lg cursor-pointer bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-blue-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-blue-800"
                >
                  Register Now
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 xl:col-span-4 lg:flex ">
            <img className="rounded-lg" src={hero} alt="coffee santa" />
          </div>
        </div>
      </section>
      <section>
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Great for Groups of Friends or Companies
            </h2>
            <p className="mb-4">
              Your friends from college or school are spread all over the country or even the world which makes it hard
              to organize group activities? Then the Music Advent Calendar might be the right thing for you and your
              friends! Share your favorite songs and the stories and memories behind them and create a unique advent
              calendar together!
            </p>
            <p>
              Or maybe you and your coworkers listen to many different genres and would like to share your favorite
              songs with each other while also getting to know each other a bit better when sharing memories and stories
              connected to the songs.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <img className="w-full rounded-lg" src={lp1} alt="vynil player" />
            <img className="mt-4 w-full lg:mt-10 rounded-lg" src={lp2} alt="christmas tree with a treble clef" />
          </div>
        </div>
      </section>
      <section>
        <div className="px-4 mx-auto max-w-screen-xl lg:px-6 py-8 lg:py-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Frequently Asked Questions
          </h2>
          <Accordion>
            <Accordion.Panel>
              <Accordion.Title>How much does it cost?</Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500 dark:text-gray-400">The Music Advent Calendar is completely free.</p>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>I like it, how can I support you?</Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  First of all, thanks! 😃 You can tell others about the experience you have so that next year even more
                  people can join and collaboratively create a calendar with their friends and coworkers.
                </p>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>Can I also support you financially?</Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  You can use Paypal donations in your user Menu. <br />
                  Additionally, as the organizer of a calendar, you'll find the option to pay for it in the calendar
                  settings. This is completely optional though! However, if you choose to support me with covering the
                  hosting costs and testing the payment process, you can choose your own price during the checkout. If
                  your company is paying for it, you can also get an invoice with your company address on it. Just
                  select “I need a full invoice” during the checkout process.
                </p>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>How many people can participate in one calendar?</Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  For this year's beta, there is a maximum of 24 people who can contribute to a single calendar. If you
                  have less than 24 people who want to participate, you can assign 2 or more doors to some or all of
                  them.
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  If you have 50 people who want to participate, you can create two calendars, add all 50 as a viewer to
                  both calendars and then assign the 24 doors of each calendar to 48 of them. Although all 50 will be
                  able to see the calendars, two won't be able to share a song and story. I am working on a “first
                  submit first serve” solution for next year's edition so that the 48 people who submit their door first
                  will be assigned to a door automatically and everyone has a fair chance to participate. You could also
                  create 3 calendars and assign more than one door to everyone who wants to submit more than one song or
                  story.
                </p>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>I want to organize a calendar, what are the biggest challenges for me?</Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Speaking from experience, the biggest challenge is that even if initially a lot of people want to
                  participate, you have to make sure they are actually submitting their song and story by the 30th of
                  November. You can use the messaging feature to specifically send emails to those participants who have
                  not submitted their door yet.
                </p>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default LandingPage
