import { SpinnerPrimaryLarge } from "./Spinner"

function FullPageSpinner() {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 flex flex-col justify-center w-full">
      <div className="mx-auto">
        <SpinnerPrimaryLarge />
      </div>
    </div>
  )
}

export default FullPageSpinner
