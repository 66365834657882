import { useNavigate, useParams } from "react-router-dom"
import { FormDivider, GenericInputField } from "../components/FormElements"
import { useDoorFetchAPI, useDoorMutationAPI } from "../hooks/api/useDoorsAPI"
import { useForm } from "react-hook-form"
import Avatar from "../assets/avatar.jpg"
import { useEffect, useState } from "react"
import { ButtonLink, SubmitButton } from "../components/Buttons"
import { useAssetMutationAPI } from "../hooks/api/useAssetsAPI"
import { RiArrowGoBackLine } from "react-icons/ri"
import { toast } from "react-toastify"

const DoorEdit = () => {
  const { doorId } = useParams<{ doorId: string }>()
  const { data, isSuccess } = useDoorFetchAPI(doorId)
  const [avatarFile, setAvatarFile] = useState<File | null>(null)
  const [avatarUrl, setAvatarUrl] = useState<string>(Avatar)
  const navigate = useNavigate()
  const assetMutations = useAssetMutationAPI()

  interface DoorUpdateFormData {
    mediaUrl: string
    body: string
    ownerName: string
    artist: string
    title: string
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<DoorUpdateFormData>({ mode: "onSubmit" })

  const doorMutations = useDoorMutationAPI(doorId)

  const onSave = handleSubmit(async (formData) => {
    navigator.clipboard.writeText(formData.body)

    let assetId
    if (avatarFile != null) {
      const finalAssetFormData = {
        file: avatarFile,
        type: "ownerImg",
        size: avatarFile?.size ? avatarFile?.size : 0,
        calendarId: data?.data.calendarId ? data?.data.calendarId : "",
      }
      assetId = (await assetMutations.create.mutateAsync(finalAssetFormData)).data.id
    }

    const finalFormData = {
      ...formData,
      ownerImg: assetId ? assetId : data?.data.ownerImg.id,
      isReady: null,
    }

    doorMutations.update.mutate(finalFormData)
  })

  const submit = () => {
    if (window.confirm("Are you sure you want to submit? You won't be able to edit this door after this.") && isValid) {
      doorMutations.ready.mutate({ isReady: true })
    }
  }

  const isLoading = doorMutations.update.isLoading || doorMutations.ready.isLoading || assetMutations.create.isLoading

  useEffect(() => {
    if (avatarFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setAvatarUrl(reader.result as string)
      }
      reader.readAsDataURL(avatarFile)
    }
  }, [avatarFile])

  useEffect(() => {
    if (assetMutations.create.isError || doorMutations.update.isError) {
      toast.info("Something went wrong during saving. But your story has been copied to your clipboard as a backup", {
        closeOnClick: true,
        autoClose: 10000,
      })
    }
  }, [assetMutations.create.isError, doorMutations.update.isError])

  useEffect(() => {
    if (data?.data?.ownerImg.url != "") {
      setAvatarUrl(data?.data?.ownerImg ? data?.data?.ownerImg.url : Avatar)
    } else {
      setAvatarUrl(Avatar)
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      setValue("mediaUrl", data?.data.mediaUrl)
      setValue("body", data?.data.body)
      setValue("ownerName", data?.data.ownerName)
      setValue("artist", data?.data.artist)
      setValue("title", data?.data.title)
    }
  }, [isSuccess])

  useEffect(() => {
    if (doorMutations.ready.isSuccess) {
      toast.success("Door submitted!")
      navigate("/")
    }
  }, [doorMutations.ready.isSuccess])

  const isSubmittable =
    data?.data.isReady === false &&
    data?.data.ownerName !== "" &&
    data?.data.artist !== "" &&
    data?.data.title !== "" &&
    data?.data.body !== "" &&
    data?.data.mediaUrl !== "" &&
    data?.data.ownerImg.url !== ""

  useEffect(() => {
    if (doorMutations.update.isSuccess) {
      navigate(`/doors/${doorId}`)
    }
  }, [doorMutations.update.isSuccess])

  useEffect(() => {
    if (assetMutations.create.isError) {
      toast.error("Error uploading image, please try a smaller image.")
      navigate("/")
    }
  }, [assetMutations.create.isError])

  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white pt-20 px-6 pb-6 min-h-screen">
      <div className="mb-7">
        <ButtonLink to={"/"}>
          <RiArrowGoBackLine className="inline-block mr-2" />
          <span>Back</span>
        </ButtonLink>
      </div>
      <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white mb-6">
        Edit Your Door
      </h1>
      <form className="space-y-4 md:space-y-6" onSubmit={onSave}>
        <div>
          <GenericInputField
            id="ownerName"
            inputType="text"
            registerHandler={() =>
              register("ownerName", {
                required: "Please enter your name",
                maxLength: {
                  value: 20,
                  message: "Name must be less than 21 characters",
                },
              })
            }
            errors={errors.ownerName}
            defaultValue={data?.data?.ownerName}
          >
            Your Name
          </GenericInputField>
        </div>
        <div>
          <label htmlFor="ownerImg" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            A Picture of You
          </label>
          <div className="grid grid-cols-2 justify-items-center items-center">
            <img src={avatarUrl} alt="Avatar" />
            <label>
              <input
                type="file"
                id="ownerImg"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => {
                  const file = e.target && e.target.files ? e.target.files[0] : null
                  file ? setAvatarFile(file) : setAvatarFile(null)
                }}
              />
              <a className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Change</a>
            </label>
          </div>
        </div>
        <FormDivider />
        <div>
          <GenericInputField
            id="artist"
            inputType="text"
            registerHandler={() => register("artist")}
            errors={errors.artist}
            defaultValue={data?.data?.artist}
          >
            Artist
          </GenericInputField>
        </div>
        <div>
          <GenericInputField
            id="title"
            inputType="text"
            registerHandler={() => register("title")}
            errors={errors.title}
            defaultValue={data?.data?.title}
          >
            Title
          </GenericInputField>
        </div>
        <div>
          <GenericInputField
            id="URL"
            inputType="text"
            registerHandler={() => register("mediaUrl")}
            errors={errors.mediaUrl}
            placeholder="Soundcloud/Youtube URL"
            defaultValue={data?.data.mediaUrl}
          >
            Link to Song
          </GenericInputField>
        </div>

        <div>
          <GenericInputField
            id="body"
            inputType="textArea"
            registerHandler={() => register("body")}
            errors={errors.body}
            defaultValue={data?.data?.body}
            placeholder="Tell the others why this song is special to you"
            rows={15}
          >
            Story
          </GenericInputField>
        </div>

        <div className="flex justify-between">
          <SubmitButton onClick={onSave} isLoading={isLoading} fullWidth>
            Save + Preview
          </SubmitButton>
          {isValid && isSubmittable && (
            <SubmitButton onClick={submit} className="ml-6" fullWidth>
              Submit
            </SubmitButton>
          )}
        </div>
      </form>
    </div>
  )
}

export default DoorEdit
