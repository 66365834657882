import React from "react"
import { useForm } from "react-hook-form"
import { SubmitButton } from "../components/Buttons"
import { GenericInputField } from "../components/FormElements"
import { requestPasswordReset } from "../AuthProvider"
import { useAsync } from "../hooks/useAsync"
import Alert from "../components/Alert"
import bg from "../assets/bg.jpg"
import { Link } from "react-router-dom"

const PasswordReset = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({ mode: "onSubmit" })

  const { run, error, isLoading, isSuccess } = useAsync()
  const onSubmit = handleSubmit((formData) => {
    run(requestPasswordReset(formData))
  })

  return (
    <section
      style={{ backgroundImage: `url(${bg})` }}
      className={` bg-no-repeat bg-cover bg-center min-h-screen bg-gray-700 bg-blend-multiply bg-opacity-60`}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
        <Link to="/" className="flex items-center mb-6 text-2xl md:text-4xl font-semibold text-white">
          Julr
        </Link>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Forgot your password?
          </h2>
          <p className="font-light text-gray-500 dark:text-gray-400">
            Don't worry! Just type in your email and we will send you a link to reset your password!
          </p>
          <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
            <div>
              <GenericInputField
                id="email"
                inputType="email"
                registerHandler={() =>
                  register("email", {
                    required: "Please enter your email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })
                }
                errors={errors.email ? errors.email : undefined}
              >
                Email
              </GenericInputField>
            </div>
            {error && error.response?.status === 500 && (
              <Alert type="error">
                Server Error during password reset. Please try another try again later or contact the support.
              </Alert>
            )}

            {isSuccess ? (
              <button
                className="text-white bg-green-800  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"
                disabled
                aria-disabled
              >
                Password reset email sent. Well, only if I found your mail in the database. Please check your email
                inbox.
              </button>
            ) : (
              <SubmitButton isLoading={isLoading} fullWidth onClick={onSubmit}>
                Send
              </SubmitButton>
            )}
          </form>
        </div>
      </div>
    </section>
  )
}

export default PasswordReset
