import { useAuth } from "../contexts/AuthContext"
import bg from "../assets/bg.jpg"
import LandingPage from "./LandingPage"
import HomePage from "./HomePage"

const Home = () => {
  const { user } = useAuth()
  if (user === null) {
    return <LandingPage />
  } else if (user) {
    return <HomePage />
  } else {
    return (
      <>
        <img src={bg} alt="record player background image" />
      </>
    )
  }
}

export default Home
