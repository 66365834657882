import { forwardRef, useState } from "react"
import { DoorCalendar, Viewers } from "../types/calendar"
import { useDoorMutationAPI } from "../hooks/api/useDoorsAPI"
import { Controller, useForm } from "react-hook-form"
import Select from "react-select"
import { SubmitButton } from "./Buttons"
import { Draggable } from "@hello-pangea/dnd"
import { RiDragMove2Line } from "react-icons/ri"

interface DoorCardEditProps {
  door: DoorCalendar
  viewers: Viewers[]
  index: number
}

interface AssignFormData {
  owner: string
}

const DoorCardEdit = forwardRef<HTMLDivElement, DoorCardEditProps>((props, ref) => {
  const { door, viewers, index } = props

  const [showAssign, setShowAssign] = useState(false)

  const { handleSubmit, control } = useForm<AssignFormData>({
    mode: "onSubmit",
  })
  const doorMutations = useDoorMutationAPI(door.id)

  const onSubmit = handleSubmit(async (formData) => {
    if (formData.owner != door.ownerId && door.ownerId != "") {
      if (
        !window.confirm(
          "Are you sure you want to assign this door to someone else? All content of the door will be lost."
        )
      ) {
        return
      }
    }
    const finalFormData = {
      owner: formData.owner,
    }

    setShowAssign(false)
    doorMutations.assignOwner.mutate(finalFormData)
  })
  const selectOptions = viewers.map((viewer) => ({
    value: viewer.id,
    label: viewer.username,
  }))

  return (
    <Draggable draggableId={door.id} key={door.id} index={index}>
      {(provided) => (
        <div
          className={
            "border border-gray-300 dark:border-gray-700 rounded-lg p-2 my-2 text-sm flex justify-between items-center text-gray-900 bg-gray-100 dark:bg-gray-600 dark:text-white"
          }
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {!showAssign ? (
            <>
              <div className="cursor-move flex items-center" {...provided.dragHandleProps}>
                <RiDragMove2Line className="mr-4" />
                {door.rank}
              </div>
              <div> {door.day === 0 ? "not in calendar" : door.day}</div>
              <div>
                {!door.ownerId ? (
                  <>
                    <span>unassigned</span>
                  </>
                ) : (
                  <span onClick={() => setShowAssign(!showAssign)}>
                    {viewers.find((v) => v.id == door.ownerId)?.username}
                  </span>
                )}
              </div>
              {door.ownerId ? (
                <label htmlFor={`green-toggle-${door.id}`} className="inline-flex relative items-center mr-5 ">
                  <input
                    type="checkbox"
                    value=""
                    id={`green-toggle-${door.id}`}
                    className="sr-only peer"
                    onChange={() => doorMutations.ready.mutate({ isReady: !door.isReady })}
                    checked={door.isReady}
                  />
                  <div className="w-11 h-6 bg-red-400 rounded-full peer  peer-focus:ring-4 peer-focus:ring-blue-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                </label>
              ) : (
                <SubmitButton onClick={() => setShowAssign(!showAssign)}>Assign</SubmitButton>
              )}
            </>
          ) : (
            <>
              {viewers.length > 0 && showAssign && (
                <>
                  <Controller
                    name="owner"
                    control={control}
                    defaultValue={door.ownerId}
                    render={({ field }) => (
                      <Select
                        className="text-black w-full "
                        ref={field.ref}
                        options={selectOptions}
                        value={selectOptions.find((option) => option.value === field.value)}
                        onChange={(val) => field.onChange(val?.value)}
                      />
                    )}
                  />

                  <button
                    type="submit"
                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5"
                    onClick={onSubmit}
                  >
                    Assign
                  </button>
                  <button
                    type="submit"
                    className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5"
                    onClick={() => setShowAssign(!showAssign)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </>
          )}
        </div>
      )}
    </Draggable>
  )
})

DoorCardEdit.displayName = "DoorCardEdit"

export default DoorCardEdit
