import React from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { FormDivider, GenericInputField } from "../components/FormElements"
import { LoginForm } from "../AuthProvider"
import { useAsync } from "../hooks/useAsync"
import Alert from "../components/Alert"
import { useAuth } from "../contexts/AuthContext"
import { useEffect } from "react"
import { SubmitButton } from "../components/Buttons"
import bg from "../assets/bg.jpg"
import { GoogleButton, MsButton } from "../components/SocialButtons"

type NavigateState = {
  from: Location
}

const Login = () => {
  const { run, error, isLoading, isSuccess } = useAsync()
  const { login, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({ mode: "onSubmit" })

  const onSubmit = handleSubmit((formData) => {
    run(login(formData))
  })

  useEffect(() => {
    if (isSuccess && user) {
      if (location.state != null) {
        const { from } = location.state as NavigateState
        const origin = from.pathname
        navigate(origin)
      } else {
        navigate("/")
      }
    }
  }, [isSuccess, user, location.state])

  return (
    <>
      <section
        style={{ backgroundImage: `url(${bg})` }}
        className={` bg-no-repeat bg-cover bg-center min-h-screen bg-gray-700 bg-blend-multiply bg-opacity-60`}
      >
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
          <Link to="/" className="flex items-center mb-6 text-2xl md:text-4xl font-semibold text-white">
            Julr
          </Link>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
            <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6">
                <div>
                  <GenericInputField
                    id="email"
                    inputType="email"
                    registerHandler={() =>
                      register("email", {
                        required: "Please enter your email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })
                    }
                    errors={errors.email ? errors.email : undefined}
                  >
                    Your Email
                  </GenericInputField>
                </div>
                <div>
                  <GenericInputField
                    id="password"
                    inputType="password"
                    registerHandler={() =>
                      register("password", {
                        required: "Please enter your password",
                      })
                    }
                    errors={errors.password ? errors.password : undefined}
                  >
                    Password
                  </GenericInputField>
                </div>
                <div className="flex items-center justify-between">
                  <Link
                    to="/reset"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </Link>
                </div>
                <SubmitButton onClick={onSubmit} isLoading={isLoading} fullWidth>
                  Sign in
                </SubmitButton>
                {error && error.response?.status === 401 && (
                  <Alert type="error">Incorrect email or password. Please try again.</Alert>
                )}
                {error && error.response?.status === 500 && (
                  <Alert type="error">
                    Server Error during Sign in. Please try another sign in method or try again later.
                  </Alert>
                )}
                <FormDivider />
                <GoogleButton />
                <MsButton />
                <p className="text-sm font-light text-center text-gray-500 dark:text-gray-300">
                  <Link to="/register" className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                    Don&apos;t have an account?
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login
